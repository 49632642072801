import React, { useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import {Container,Typography,styled, useTheme,Divider,Box, TextField, TableContainer, Table, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, Tooltip, IconButton, Snackbar, Backdrop, CircularProgress, Button} from '@mui/material';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { addStateIdSatuanBarang, emptyListRincianStokBarang, getAllStokBarang, setStokBarangMessage, syncBookingStok } from '../../../config/redux/actions';
import { TablePagination } from '../../../components/molecules';
import Visibility from '@mui/icons-material/Visibility';
import { useHistory } from 'react-router-dom';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'grey',
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const SemuaStokBarang = () => {
    const theme = useTheme();
    const paperTable = {
        marginTop:'10px',
        [theme.breakpoints.up('sm')]:{
            width:'60%',
            height:'400px',
        }, 
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'360px'
        },
        scrollbarWidth:'thin'
    }
    const boxSearch = {
        [theme.breakpoints.up('sm')]:{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'flex-end',
            width:'60%',
        }, 
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'flex-end',
            width:'100%',
        }
    }
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const {stokbarangMessage,pageStokBarang,isLoading,dataStokBarang} =useSelector(state=>state.stokbarangReducers);
    const dispatch = useDispatch();
    const [token,setToken] = useState(dataLogin.token);
    const [search,setSearch] = useState('');
    const [counter,setCounter] = useState(1);
    const [openSnackbar,setOpensnackbar]=useState(false);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const handleChangeSearch = (e) =>{
        setSearch(e.target.value);
        dispatch(getAllStokBarang(e.target.value,counter,token,setOpensnackbar));
    }
    useEffect(()=>{
        dispatch(getAllStokBarang(search,counter,token,setOpensnackbar));
    },[search,counter,token,dispatch])
    const handlePrev = () =>{
        setCounter(counter <= 1? 1 : counter - 1);
    }
    const handleNext = () =>{
        setCounter(counter === pageStokBarang.totalPage ? pageStokBarang.totalPage : counter + 1);
    }
    const history = useHistory();
    const handleOpenRincian = (idsatuanbarang,namabarang,idbarang,idsatuan)=>{
        dispatch(emptyListRincianStokBarang());
        const data = {
            'idsatuanbarang':idsatuanbarang,
            'namabarang':namabarang,
            'idbarang':idbarang,
            'idsatuan':idsatuan
        }
        dispatch(addStateIdSatuanBarang(data));
        history.push(`/stokbarang/rincian`);
    }
    const tableStokBarang = (
        <TableBody>
            {dataStokBarang.map((stokbarangs) => (
                <StyledTableRow key={stokbarangs.idstokbarang}>
                <StyledTableCell width={120} align="center">{stokbarangs.idbarang}</StyledTableCell>
                <StyledTableCell width={200} align="center">{stokbarangs.namabarang}</StyledTableCell>
                <StyledTableCell width={120} align="center">{stokbarangs.satuan}</StyledTableCell>
                <StyledTableCell width={120} align="center">{stokbarangs.sisastok}</StyledTableCell>
                <StyledTableCell width={120} align="center">{stokbarangs.bookingstok}</StyledTableCell>
                <StyledTableCell width={120} align="center">{parseInt(stokbarangs.sisastok)-parseInt(stokbarangs.bookingstok)}</StyledTableCell>
                <StyledTableCell width={15} align="center">
                    <Tooltip title="Lihat rincian stok">
                        <IconButton 
                        onClick={()=>handleOpenRincian(stokbarangs.idsatuanbarang,stokbarangs.namabarang,stokbarangs.idbarang,stokbarangs.idsatuan)}
                        >
                            <Visibility/>
                        </IconButton>
                    </Tooltip>
                </StyledTableCell>
                </StyledTableRow>
            ))}
        </TableBody>
    )
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(setStokBarangMessage(''));
        }, 100);
    }
    const handleClickSync = () =>{
        dispatch(syncBookingStok(token,setOpensnackbar));
    }
  return (
    <Container maxWidth='xl' sx={{marginTop:'100px'}}>
    <Typography variant='h6' pl={1} mt={1}>Data Stok Barang</Typography>
    <Divider/>
    <Box sx={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
        <Box sx={{width:'60%',display:'flex',justifyContent:'space-between'}}>
            <Button 
            variant='contained' 
            sx={{height:'30px',marginTop:'15px'}}
            onClick={()=>handleClickSync()}
            >Sync Booking Stok</Button>
            <TextField
                id='search'
                name='search'
                label='Cari Data Barang'
                variant='standard'
                onChange={handleChangeSearch}
                />
        </Box>
            <TableContainer component={Paper} sx={paperTable}>
                <Table stickyHeader aria-label="customized table" size='small'>
                    <TableHead>
                    <TableRow>
                        <StyledTableCell width={120} align="center">ID Barang</StyledTableCell>
                        <StyledTableCell width={200} align="center">Nama Barang</StyledTableCell>
                        <StyledTableCell width={120} align="center">Satuan</StyledTableCell>
                        <StyledTableCell width={120} align="center">Sisa Stok</StyledTableCell>
                        <StyledTableCell width={120} align="center">Booking Stok</StyledTableCell>
                        <StyledTableCell width={120} align="center">Stok Tersedia</StyledTableCell>

                        <StyledTableCell width={15} align="center"/>
                    </TableRow>
                    </TableHead>
                        {tableStokBarang} 
                </Table>
            </TableContainer>
            <Box sx={boxSearch}>
                <TablePagination
                    page={counter}
                    totalPage={pageStokBarang.totalPage >= 1 ? pageStokBarang.totalPage : pageStokBarang.currentPage}
                    handlePrev={handlePrev}
                    handleNext={handleNext}
                    hiddenButton={true}
                />
            </Box>
    </Box>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        >
        <CircularProgress color="inherit" />
    </Backdrop>
    <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={stokbarangMessage === "Berhasil menampilkan data stok barang" || 
                "Sinkronisasi booking stok berhasil dilakukan" ? "success" : "error"}
                >
                    {stokbarangMessage}
                    </Alert>
            </Snackbar>
    </Container>
  )
}

export default SemuaStokBarang