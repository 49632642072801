import { Container, Divider, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system';
import React from 'react'
import { Link } from 'react-router-dom';
import { LaporanPelangganMenuIcon, LaporanPembelianDistributor, LaporanPembelianMenuIcon, LaporanPenjualanMenuIcon, ReturnPembelianMenu, ReturnPenjualanMenu } from '../../../components/assets';
import {  ReportMenu } from '../../../components/molecules';

const LaporanPage = () => {
    const theme = useTheme();
    
    const paperList={
        [theme.breakpoints.up('sm')]:{
            width:'600px',
            height:'140px',
            display:'flex',
            justifyContent:'space-between',
            marginTop:'20px',
        },
        [theme.breakpoints.down('sm')]:{
            width:'150px',
            height:'900px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            marginTop:'20px'
        }
    }
    // const menuList={
    //     [theme.breakpoints.up('sm')]:{
    //         width:'600px',
    //         height:'140px',
    //         display:'flex',
    //         justifyContent:'center',
    //         marginTop:'20px',
    //     },
    //     [theme.breakpoints.down('sm')]:{
    //         width:'150px',
    //         height:'900px',
    //         display:'flex',
    //         flexDirection:'column',
    //         justifyContent:'center',
    //         marginTop:'20px'
    //     }
    // }
  return (
        <Container maxWidth='xl' sx={{marginTop:'100px'}}>
            <Typography variant='h6' pl={1} mt={1}>Menu Laporan</Typography>
            <Divider/>
            <Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                <Box sx={paperList}>
                    <Link to="/laporan/pembelian" style={{textDecoration:'none',color:'black'}}>
                        <ReportMenu cardIcon={LaporanPembelianMenuIcon} cardLabel="Laporan Pembelian" altIcon="LaporanPembelianMenu"/>
                    </Link>
                    <Link to="/laporan/distributor" style={{textDecoration:'none',color:'black'}}>
                        <ReportMenu cardIcon={LaporanPembelianDistributor} cardLabel="Pembelian Per Distributor" altIcon="LaporanDistributorMenu"/>
                    </Link>
                    <Link to="/laporan/penjualan" style={{textDecoration:'none',color:'black'}}>
                        <ReportMenu cardIcon={LaporanPenjualanMenuIcon} cardLabel="Laporan Penjualan" altIcon="LaporanPenjualanMenu"/>
                    </Link>
                </Box>
                <Box sx={paperList}>
                    <Link to="/laporan/pelanggan" style={{textDecoration:'none',color:'black'}}>
                        <ReportMenu cardIcon={LaporanPelangganMenuIcon} cardLabel="Penjualan Per Pelanggan" altIcon="LaporanPelangganMenu"/>
                    </Link>
                    <Link to="/laporan/returpembelian" style={{textDecoration:'none',color:'black'}}>
                        <ReportMenu cardIcon={ReturnPembelianMenu} cardLabel="Retur Pembelian" altIcon="ReturPembelianMenu"/>
                    </Link>
                    <Link to="/laporan/returpenjualan" style={{textDecoration:'none',color:'black'}}>
                        <ReportMenu cardIcon={ReturnPenjualanMenu} cardLabel="Retur Penjualan" altIcon="ReturPenjualanMenu"/>
                    </Link>
                </Box>
            </Box>
        </Container>
  )
}

export default LaporanPage