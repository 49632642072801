import { Box, ListItemButton, Typography,useTheme } from '@mui/material';
import React from 'react';

const CardMenu = ({cardLabel,cardIcon,altIcon,handleClick,disabled}) => {
    const theme = useTheme();
    const paperCard = {
        [theme.breakpoints.up('sm')]:{
            width:'130px',
            height:'130px',
            margin:'5px'
        },
        [theme.breakpoints.down('sm')]:{
            width:'90%',
            height:'90%',
            margin:'5px'
        }
    }
    const listItem={
        width:'100%',
        height:'100%',
        display:'flex',
        flexDirection:'column',
        ":hover":{border:'solid #0072cf'}
    }
    return (
        <Box sx={paperCard}>
            <ListItemButton sx={listItem} onClick={handleClick} disabled={disabled}>
            <Box borderRadius="30px" bgcolor="#5e5c5c" sx={{width:'100%',height:'80%',display:'flex',justifyContent:'center',
            alignContents:'center',":hover":{backgroundColor:'#0072cf'}}}>
                <img src={cardIcon} alt={altIcon} style={{backgrounPosition:'center', width:'80px',height:'80px'}}/>
            </Box>
            <Box sx={{width:'100%',textAlign:'center'}}>
                <Typography>{cardLabel}</Typography>
            </Box>
            </ListItemButton>
        </Box>
    )
}

export default CardMenu
