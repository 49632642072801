import { Backdrop, CircularProgress, Container, Divider, Fab, IconButton, List, ListItem, ListItemText, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import MuiAlert from '@mui/material/Alert';
import {emptyKonversiMessage,getSatuanKonversiList, getAllKonversi, addNewKonversi, errorMessageKonversi, deleteKonversiBarang} from '../../../config/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteDialog } from '../../../components/molecules';
import { AddTodo } from '../../../components/assets';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { useHistory } from 'react-router-dom';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


const KonversiPage = () => {
    const theme = useTheme();
    const boxWapper = {
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            maxHeight:'500px',
            height:'100%',
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'100%',
        }
    }
    const paperInput={
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        // border:'1px solid black',
        [theme.breakpoints.up('sm')]:{
            width:"35%",
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        },
        [theme.breakpoints.down('sm')]:{
            width:'90%',
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        }
    }
    const boxTodo = {
        [theme.breakpoints.up('sm')]:{
            width:'400px',
            marginTop:'10px',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'flex-end'
        },
        [theme.breakpoints.down('sm')]:{
            width:'70%',
            marginTop:'10px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center'
        }
    }
    const boxList = {
        [theme.breakpoints.up('sm')]:{
            width:'400px',
            marginTop:'10px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-between',
            alignItems:'flex-end'
        },
        [theme.breakpoints.down('sm')]:{
            width:'80%',
            marginTop:'10px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center'
        }
    }
    const widthListTodo ={
        [theme.breakpoints.up('sm')]:{
            width:"150px"
        },
        [theme.breakpoints.down('sm')]:{
            width:'250px',
            textAlign:'center'
        }
    }
    const widthTodo ={
        [theme.breakpoints.up('sm')]:{
            width:"100px"
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
        }
    }
    const {konversiMessage,isLoading,dataKonversi,arrSatuanKonversi} = useSelector(state=>state.konversiReducers);
    const {dataLogin} = useSelector(state=>state.penggunaReducers);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [token,setToken] = useState(dataLogin.token);
    const [openDeleteDialog,setOpenDeleteDialog]=useState(false);
    const [messageDialog,setMessageDialog]=useState('');
    const [tempIdKonversiBarang,setTempIdKonversiBarang] = useState('');
    let {idbarang} = useParams();
    let{namabarang} = useParams();
    const dispatch = useDispatch();
    const history=useHistory();
    useEffect(()=>{
        dispatch(getAllKonversi(idbarang,token,setOpensnackbar));
        dispatch(getSatuanKonversiList(idbarang,token,setOpensnackbar));
    },[token,dispatch,idbarang])
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        dispatch(getAllKonversi(idbarang,token,setOpensnackbar))
        setTimeout(() => {
            dispatch(emptyKonversiMessage());
        }, 100);
    }
    
    const handleOpenDialog = (idkonversi,darisatuan,kesatuan)=>{
        setMessageDialog(`Apakah anda yakin akan menghapus data konversi ${darisatuan} ke ${kesatuan} dari barang (${namabarang})?`);
        setTempIdKonversiBarang(idkonversi);
        setOpenDeleteDialog(true);
    }
    const handleCloseDialog = ()=>{
        setOpenDeleteDialog(false);
    }
    const handleDeleteKonversi = () =>{
        dispatch(deleteKonversiBarang(tempIdKonversiBarang,token,setOpensnackbar,setOpenDeleteDialog));
    }
    const listSatuan = (
        <List sx={boxList}>
            {dataKonversi.map((list)=>(
                <ListItem key={list.idkonversi}
                disableGutters
                sx={{width:'100%',display:'flex',flexDirection:'column'}}
                >
                    <Box sx={{width:'100%',display:'flex'}}>
                        <ListItemText sx={widthListTodo} primary={list.darisatuan}/>
                        <ListItemText sx={widthListTodo} primary={list.kesatuan}/>
                        <ListItemText sx={widthListTodo} primary={list.jumlah}/>
                        <IconButton size='small' 
                        onClick={()=>handleOpenDialog(list.idkonversi,list.darisatuan,list.kesatuan,list.namabarang)}
                        >
                        <DeleteIcon sx={{color:'red'}}/>
                        </IconButton>
                    </Box>
                        <Divider sx={{width:'100%'}}/>
                </ListItem>
            ))}
        </List>
    )
    const schemaValidation = yup.object().shape({
        idbarang:yup.string(),
        darisatuan:yup.string(),
        kesatuan:yup.string(),
        jumlah:yup.number().min(0,"Jumlah tidak boleh kurang dari 0")
    })
    const formik = useFormik({
        initialValues:{
            tempIdbarang:idbarang||'',
            darisatuan:'',
            kesatuan:'',
            jumlah:''
        },
        validationSchema:schemaValidation,
        onSubmit:(values)=>{
            const existDariSatuanFromData = dataKonversi.filter(konversiData=>konversiData.iddarisatuan === values.darisatuan);
            const existKeSatuanFromData = dataKonversi.filter(konversiData=>konversiData.idkesatuan === values.kesatuan);
            if(existDariSatuanFromData.length > 0 && existKeSatuanFromData.length > 0){
                dispatch(errorMessageKonversi("Satuan untuk barang ini telah terdaftar",setOpensnackbar));
            }else{
                if(values.darisatuan === "" || values.kesatuan==="" || values.jumlah===""){
                    dispatch(errorMessageKonversi("Data yang di masukkan tidak sesuai",setOpensnackbar));
                }else if(values.darisatuan === values.kesatuan){
                    dispatch(errorMessageKonversi("Tidak dapat konversi untuk satuan yang sama",setOpensnackbar));
                }
                else{
                    console.log(values.darisatuan);
                    const data = new URLSearchParams();
                    data.append('idbarang',values.tempIdbarang);
                    data.append('darisatuan',values.darisatuan);
                    data.append('kesatuan',values.kesatuan);
                    data.append('jumlah',values.jumlah);
                    dispatch(addNewKonversi(data,token,setOpensnackbar,formik));
                }
            }
        }
    })
    return (
        <Container maxWidth='xl' sx={{marginTop:'100px'}}>
        <Typography variant='h6' pl={1} mt={1}>Rincian Data Barang</Typography>
        <Divider/>
        <Box sx={boxWapper}>
            <Box sx={paperInput}>
                <TextField
                id="idbarang"
                name="idbarang"
                label="ID Barang"
                variant="standard"
                disabled={true}
                style={{width:'80%',marginTop:'10px'}}
                value={formik.values.tempIdbarang}
                />
                 <TextField
                id="namabarang"
                name="namabarang"
                label="Nama Barang"
                variant="standard"
                disabled={true}
                style={{width:'80%',marginTop:'10px'}}
                value={namabarang}
                />
            </Box>
            <Box sx={boxTodo}>
                    <TextField
                    id='darisatuan'
                    select
                    name='darisatuan'
                    variant="standard" 
                    label="Satuan"
                    sx={widthTodo}
                    SelectProps={{
                        native: true,
                      }}
                    value={formik.values.darisatuan}
                    error={formik.touched.darisatuan && Boolean(formik.errors.darisatuan)}
                    helperText={formik.touched.darisatuan && formik.errors.darisatuan}
                    onChange={formik.handleChange}
                    >   
                        <option aria-label="None" value="" />
                        {arrSatuanKonversi.map((option)=>(
                            <option key ={option.idsatuan} value={option.idsatuan}>
                                {option.satuan}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                    id='kesatuan'
                    select
                    name='kesatuan'
                    variant="standard" 
                    label="Ke Satuan"
                    sx={widthTodo}
                    SelectProps={{
                        native: true,
                      }}
                    value={formik.values.kesatuan}
                    error={formik.touched.kesatuan && Boolean(formik.errors.kesatuan)}
                    helperText={formik.touched.kesatuan && formik.errors.kesatuan}
                    onChange={formik.handleChange}
                    >   
                        <option aria-label="None" value="" />
                        {arrSatuanKonversi.map((option)=>(
                            <option key ={option.idsatuan} value={option.idsatuan}>
                                {option.satuan}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                    id="jumlah"
                    name="jumlah"
                    label="Jumlah"
                    variant="standard"
                    sx={widthTodo}
                    value={formik.values.jumlah}
                    error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
                    helperText={formik.touched.jumlah && formik.errors.jumlah}
                    onChange={formik.handleChange}
                    />
                    <IconButton onClick={formik.handleSubmit}>
                        <img src={AddTodo} alt='addtodo'/>
                    </IconButton>
                    </Box>
                    {dataKonversi.length>0 ? listSatuan : <Typography variant='body' sx={{marginTop:'10px'}}>Belum terdapat konversi untuk barang ini</Typography>}
                </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={konversiMessage === 'Penghapusan data konversi barang berhasil' || konversiMessage === 'Penambahan data konversi berhasil' ? "success" : "error"}
                >
                    {konversiMessage}
                    </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
            <DeleteDialog 
            openDialog={openDeleteDialog} 
            message={messageDialog}
            handleCloseDialog={handleCloseDialog}
            handleClick={handleDeleteKonversi}
            isLoading={isLoading}/>
            <Fab sx={{position: 'fixed',bottom: theme.spacing(5),left: theme.spacing(3)}} size="small" color="primary" aria-label="add" onClick={()=>history.push('/barang')}>
            <ArrowBackIcon/>
        </Fab>
        </Container>
    )
}

export default KonversiPage
