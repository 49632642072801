import axios from 'axios';
import { setEmptyDataPelanggan } from '.';
import { apiUrl } from '../..';

export const getFakturPenjualan = (tanggal,token,setOpensnackbar,savePenjualan,formik,formikDraftPenjualan) =>(dispatch) =>{
    dispatch({
        type:'FETCH ACTION PENJUALAN'
    })
    axios.get(`${apiUrl.url}penjualan/nofaktur/?tanggal=${tanggal}`,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED GET NO FAKTUR PENJUALAN',
            payload:ResponseAPI.data
        })
        if(savePenjualan==="Draft"){
            formikDraftPenjualan.setFieldValue('fakturpenjualan',ResponseAPI.data);
        }else{
            formik.setFieldValue('fakturpenjualan',ResponseAPI.data);
        }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const getHargaJual = (data,token,formikCart,setOpensnackbar) =>(dispatch) =>{
    dispatch({
        type:'FETCH ACTION PENJUALAN'
    })
    axios.post(`${apiUrl.url}penjualan/getharga`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'GET HARGA JUAL',
            payload:ResponseAPI.data
        })
        formikCart.setFieldValue('harga',ResponseAPI.data)
        formikCart.setFieldValue('qty',0);
        formikCart.setFieldValue('jumlah',0);
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const emptyHargaJual = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY HARGA JUAL'
    })
}

export const addToCartPenjualan = (data,token,formikCart,setOpensnackbar)=>(dispatch)=>{
    const editBooking={
        'idbarang':data.idbarang,
        'idsatuan':data.idsatuan,
        'qty':data.qty,
        'status':"Tambah"
    }
    axios.post(`${apiUrl.url}satuanbarang/singleeditbooking`,editBooking,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
            dispatch({
                type:'SUCCESSED ACTION DRAFT',
                payload:ResponseAPI.message
            })
        })
        .catch(error=>{
            dispatch({
                type:'FAILED ACTION DRAFT',
                payload:error.message
            })
            setOpensnackbar(true);
        })
        dispatch({
            type:'ADD TO CART PENJUALAN',
            payload:data
        })
        formikCart.resetForm();
}
export const getLastStokQty = (data,token,setOpensnackbar)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PENJUALAN'
    })
    axios.post(`${apiUrl.url}penjualan/laststokbarang`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseApi = res.data;
        dispatch({
            type:'SUCCESSED GET LAST STOK QTY',
            payload:ResponseApi.data,
            message:ResponseApi.message
        })
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}

export const setPenjualanMessage = (message)=>(dispatch)=>{
    dispatch({
        type:'SET PENJUALAN MESSAGE',
        payload:message
    })
}

export const deleteItemPenjualan = (id,data,token,setOpensnackbar) =>(dispatch)=>{
    dispatch({
        type:'FETCH DRAFT'
      })
    const editBooking={
        'idbarang':data.idbarang,
        'idsatuan':data.idsatuan,
        'qty':data.qty,
        'status':"Kurang"
      }
      axios.post(`${apiUrl.url}satuanbarang/singleeditbooking`,editBooking,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
        })
      .then(res=>{
          const ResponseAPI=res.data;
          dispatch({
            type:'SUCCESSED ACTION DRAFT',
            payload:ResponseAPI.message
          })
        })
        .catch(error=>{
          dispatch({
            type:'FAILED ACTION DRAFT',
            payload:error.message
          })
          setOpensnackbar(true);
        })
        dispatch({
            type:'DELETE CART PENJUALAN',
            payload:id
        })
}

export const postPenjualan = (data,token,setOpensnackbar,formik,history,statusSave,returPenjualan,setTipeHarga) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PENJUALAN'
    })
    axios.post(`${apiUrl.url}penjualan`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseApi = res.data;
        if(ResponseApi.message==="Stok tidak terpenuhi"){
            dispatch({
            type:'FAILED ACTION PENJUALAN',
            payload:"Penjualan gagal disimpan dikarenakan terdapat stok yang tidak terpenuhi,\nStok barang yang kosong (Kode Barang) "+ResponseApi.data[0].idbarang+" dengan selisih "+ResponseApi.data[0].selisih+" "+ResponseApi.data[0].satuan
            })
            setOpensnackbar(true);
        }else{
            dispatch({
                type:'SUCCESSED POST PENJUALAN',
                payload:ResponseApi.message,
                faktursave:ResponseApi.tempNewFaktur
            })
            returPenjualan(ResponseApi.tempNewFaktur,data.idpelanggan,data.tanggal);
            setTipeHarga("Harga Jual");
            formik.handleReset();
            setOpensnackbar(true);
            dispatch({
                type:'EMPTY CART PENJUALAN'
            })
                axios.get(`${apiUrl.url}penjualan/nofaktur/?tanggal=${data.tanggal}`,{
                    headers:{
                        "Authorization" : `Bearer ${token}`,
                    }
                })
                .then(res=>{
                    const ResponseAPI = res.data;
                    dispatch({
                        type:'SUCCESSED GET NO FAKTUR PENJUALAN',
                        payload:ResponseAPI.data
                    })
                    if(statusSave==="SimpanPrint"){
                        history.push(`/laporan/penjualan/${ResponseApi.tempNewFaktur}`)
                        formik.setFieldValue('fakturpenjualan',ResponseAPI.data)
                    }else if(statusSave==="Simpan"){
                        history.push(`/penjualan/`)
                        formik.setFieldValue('fakturpenjualan',ResponseAPI.data)
                    }
                })
                .catch(error=>{
                    dispatch({
                    type:'FAILED ACTION PENJUALAN',
                    payload:error.message
                    })
                    setOpensnackbar(true);
                })
            }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const setEmptyFakturPenjualan = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY FAKTUR PENJUALAN'
    })
}

export const getDataPenjualan = (data,token,setOpensnackbar)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PENJUALAN'
    })
    axios.post(`${apiUrl.url}penjualan/datapenjualan`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'SUCCESSED GET DATA PENJUALAN',
            payload:ResponseAPI.data
        })
    }) 
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const getDataPenjualanPelanggan = (data,token,setOpensnackbar)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PENJUALAN'
    })
    axios.post(`${apiUrl.url}penjualan/penjualanpelanggan`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'SUCCESSED GET PENJUALAN PELANGGAN',
            payload:ResponseAPI.data,
            retur:ResponseAPI.retur
        })
    }) 
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const getDataPenjualanToPrint = (data,token,setOpensnackbar)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PENJUALAN'
    })
    axios.post(`${apiUrl.url}penjualan/rincian`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        axios.post(`${apiUrl.url}penjualan/penjualandetails`,data,{
            headers:{
                "Authorization" : `Bearer ${token}`,
            }
        })
        .then(resDet=>{
            const ResponseAPIDetails=resDet.data;
            dispatch({
                type:'SUCCESSED GET PENJUALAN TO PRINT',
                payload:ResponseAPI.data[0],
                details:ResponseAPIDetails.data
            })
        })
        .catch(error=>{
            dispatch({
              type:'FAILED ACTION PENJUALAN',
              payload:error.message
            })
            setOpensnackbar(true);
        })
    }) 
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}

export const setEmptyPenjualanToPrint = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY PENJUALAN TO PRINT'
    })
}
export const setEmptyPenjualanPelanggan = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY PENJUALAN PELANGGAN'
    })
}
export const setEmptyDataPenjualan = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY DATA PENJUALAN'
    })
}
export const getPenjualanDetailsPPN = (data,token,setOpensnackbar)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PENJUALAN'
    })
    axios.post(`${apiUrl.url}penjualan/rincian`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        axios.post(`${apiUrl.url}penjualan/detailsppn/`,data,{
            headers:{
                "Authorization" : `Bearer ${token}`,
            }
        })
        .then(resDet=>{
            const ResponseAPIDet = resDet.data;
            dispatch({
                type:'SUCCESSED GET PENJUALAN',
                payload:ResponseAPI.data[0]
            })
            dispatch({
                type:'SUCCESSED GET PENJUALAN DETAILS',
                payload:ResponseAPIDet.data
            })
        })
        .catch(error=>{
            dispatch({
              type:'FAILED ACTION PENJUALAN',
              payload:error.message
            })
            setOpensnackbar(true);
        })
    })
}

export const pembatalanPenjualan = (data,token,setOpensnackbar,setOpenDialog)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PENJUALAN'
    })
    axios.post(`${apiUrl.url}penjualan/pembatalan`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED PEMBATALAN PENJUALAN',
            payload:ResponseAPI.message
        })
        setOpensnackbar(true);
        setOpenDialog(false);
        axios.post(`${apiUrl.url}penjualan/datapenjualan`,data,{
            headers:{
                "Authorization" : `Bearer ${token}`,
            }
        })
        .then(res=>{
            const ResponseAPI=res.data;
            dispatch({
                type:'SUCCESSED GET DATA PENJUALAN',
                payload:ResponseAPI.data
            })
        }) 
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const scanBarcode = (data,tipeHarga,token,setOpensnackbar,formikCart,getSatuanKonversiList,setSatuanText) =>(dispatch)=>{
    dispatch({
      type:'FETCH ACTION PENJUALAN'
    })
    axios.post(`${apiUrl.url}satuanbarang/bybarcode/`,data,{
      headers:{
        "Authorization" : `Bearer ${token}`,
      }
    })
    .then((res)=>{
      const ResponseAPI=res.data;
      dispatch({
        type:'SUCESSED GET DATA BARCODE',
        payload:ResponseAPI.data[0],
        message:ResponseAPI.message
      })
      if(ResponseAPI.message==='Berhasil menampilkan data barcode'){
        dispatch(getSatuanKonversiList(ResponseAPI.data[0].idbarang,token,""));
        const dataLastStok = {
            'idbarang':ResponseAPI.data[0].idbarang,
            'idsatuan':ResponseAPI.data[0].idsatuan,
            'qty':1
            }
        dispatch(getLastStokQty(dataLastStok,token,setOpensnackbar));
        setSatuanText(ResponseAPI.data[0].satuan);
        formikCart.setFieldValue('idbarang',ResponseAPI.data[0].idbarang);
        formikCart.setFieldValue('namabarang',ResponseAPI.data[0].namabarang);
        formikCart.setFieldValue('idsatuan',ResponseAPI.data[0].idsatuan);
        formikCart.setFieldValue('diskon',0);
        formikCart.setFieldValue('qty',1);
        if(tipeHarga==="Harga Jual"){
            formikCart.setFieldValue('harga',ResponseAPI.data[0].hargadasar);
            formikCart.setFieldValue('jumlah',ResponseAPI.data[0].hargadasar);
        }else if(tipeHarga==="Harga Grosir"){
            formikCart.setFieldValue('harga',ResponseAPI.data[0].hargagrosir);
            formikCart.setFieldValue('jumlah',ResponseAPI.data[0].hargagrosir);
        }
        setOpensnackbar(false);
        setTimeout(()=>{
            formikCart.handleSubmit();
        },1000)
      }else if(ResponseAPI.message==='Barcode tidak terdaftar'){
        setOpensnackbar(true);
      }
    })
    .catch(error=>{
      dispatch({
        type:'FAILED ACTION PENJUALAN',
        payload:error.message
      })
      setOpensnackbar(true);
    })
  }
  export const getAllPenjualanDetails = (data,token,setOpensnackbar)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PENJUALAN'
    })
    axios.post(`${apiUrl.url}penjualan/alldetails`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        console.log(ResponseAPI)
        dispatch({
            type:'SUCESSED GET ALL PENJUALAN DETAILS',
            payload:ResponseAPI.data
          })
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
      })
}
export const plusButton = (index)=>(dispatch)=>{
    dispatch({
        type:'UPDATE ITEM PLUS',
        id:index
    })
}
export const MinusButton = (index,editBookingStok,token,setOpensnackbar,status)=>(dispatch)=>{
    dispatch({
        type:'FETCH DRAFT'
      })
        axios.post(`${apiUrl.url}satuanbarang/singleeditbooking`,editBookingStok,{
          headers:{
              "Authorization" : `Bearer ${token}`,
          }
          })
          .then(res=>{
            const ResponseAPI=res.data;
            dispatch({
              type:'SUCCESSED ACTION DRAFT',
              payload:ResponseAPI.message
            })
            if(status==="Minus"){
                dispatch({
                    type:'UPDATE ITEM MINUS',
                    id:index
                })
            }
          })
          .catch(error=>{
            dispatch({
              type:'FAILED ACTION DRAFT',
              payload:error.message
            })
            setOpensnackbar(true);
          })
   
}
export const clearLastStok = ()=>(dispatch)=>{
    dispatch({
        type:'EMPTY LAST STOK QTY'
    })
}
export const getLastQtyTable = (index,data,token,setOpensnackbar,statusDraft)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PENJUALAN'
    })
    axios.post(`${apiUrl.url}penjualan/laststokbarang`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseApi = res.data;
        setOpensnackbar(false);
        dispatch({
            type:'SUCCESSED GET LAST STOK QTY',
            payload:ResponseApi.data,
            message:ResponseApi.message
        })
        // var correctStok ;
        var newStok;
        // if(ResponseApi.data.bookingstok>=data.qty){
            // correctStok = parseInt(ResponseApi.data.bookingstok)+1;
            newStok = parseInt(ResponseApi.data.stok)-1;
        // }else if(ResponseApi.data.bookingstok<data.qty){
        //     newStok = ResponseApi.data.stok - data.qty;
        // }
        if(newStok>=0){
            if(statusDraft==="Draft"){
                const editBooking={
                    'idbarang':data.idbarang,
                    'idsatuan':data.idsatuan,
                    'qty':1,
                    'status':"Tambah"
                }
                dispatch({
                    type:'FETCH DRAFT'
                  })
                axios.post(`${apiUrl.url}satuanbarang/singleeditbooking`,editBooking,{
                    headers:{
                        "Authorization" : `Bearer ${token}`,
                    }
                })
                .then(res=>{
                    const ResponseAPI=res.data;
                    dispatch({
                        type:'SUCCESSED ACTION DRAFT',
                        payload:ResponseAPI.message
                    })
                    })
                    .catch(error=>{
                    dispatch({
                        type:'FAILED ACTION DRAFT',
                        payload:error.message
                    })
                    setOpensnackbar(true);
                    })
                    dispatch({
                        type:'UPDATE ITEM PLUS',
                        id:index
                    })
            }else if(statusDraft==="EditDraft"){
                let newQty = data.qty+1;
                let newJumlah = newQty * data.harga;
                const newData={
                    'idpenjualandetails':data.idpenjualandetails,
                    'qty':newQty,
                    'jumlah':newJumlah
                }
                dispatch({
                    type:'FETCH DRAFT'
                  })
                  axios.patch(`${apiUrl.url}draftpenjualan/editDraft`,newData,{
                    headers:{
                      "Authorization" : `Bearer ${token}`,
                    }
                  })
                  .then(res=>{
                      const editBooking={
                          'idbarang':data.idbarang,
                          'idsatuan':data.idsatuan,
                          'qty':1,
                          'status':"Tambah"
                      }
                      axios.post(`${apiUrl.url}satuanbarang/singleeditbooking`,editBooking,{
                        headers:{
                            "Authorization" : `Bearer ${token}`,
                        }
                        })
                        .then(res=>{
                            const ResponseAPI=res.data;
                            dispatch({
                              type:'SUCCESSED ACTION DRAFT',
                              payload:ResponseAPI.message
                            })
                          })
                          .catch(error=>{
                            dispatch({
                              type:'FAILED ACTION DRAFT',
                              payload:error.message
                            })
                            setOpensnackbar(true);
                          })
                            dispatch({
                                type:'UPDATE ITEM PLUS',
                                id:index
                            })
                        })
                    .catch(error=>{
                        dispatch({
                            type:'FAILED ACTION PENJUALAN',
                            payload:error.message
                        })
                        setOpensnackbar(true);
                    })
            }
        }else{
            dispatch({
                type:'SET PENJUALAN MESSAGE',
                payload:"Stok barang tidak mencukupi"
            })
            setOpensnackbar(true);
        }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const postDraftPenjualan = (data,token,setOpensnackbar,formikDraftPenjualan,history,statusSave,draftReturPenjualan,setTipeHarga) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PENJUALAN'
    })
    axios.post(`${apiUrl.url}draftPenjualan`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseApi = res.data;
        dispatch({
            type:'SUCCESSED POST PENJUALAN',
            payload:ResponseApi.message,
            faktursave:ResponseApi.tempNewFaktur
        })
        draftReturPenjualan(ResponseApi.tempNewFaktur,data.idpelanggan,data.tanggal);
        setTipeHarga("Harga Jual");
        dispatch(setEmptyDataPelanggan());
        formikDraftPenjualan.handleReset();
        setOpensnackbar(true);
        dispatch({
            type:'EMPTY CART PENJUALAN'
        })
            axios.get(`${apiUrl.url}penjualan/nofaktur/?tanggal=${data.tanggal}`,{
                headers:{
                    "Authorization" : `Bearer ${token}`,
                }
            })
            .then(res=>{
                const ResponseAPI = res.data;
                dispatch({
                    type:'SUCCESSED GET NO FAKTUR PENJUALAN',
                    payload:ResponseAPI.data
                })
                if(statusSave==="SimpanPrint"){
                    history.push(`/laporan/penjualan/${ResponseApi.tempNewFaktur}`)
                    window.location.reload();
                }else if(statusSave==="Simpan"){
                    history.push(`/penjualan/`)
                    formikDraftPenjualan.setFieldValue('fakturpenjualan',ResponseAPI.data)
                    window.location.reload();
                }
            })
            .catch(error=>{
                dispatch({
                type:'FAILED ACTION PENJUALAN',
                payload:error.message
                })
                setOpensnackbar(true);
            })
        })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}

export const setEmptyCartPenjualan = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY CART PENJUALAN'
    })
}

export const transferDraftToPenjualan = (data,token,setOpensnackbar,formik,history,statusSave,transferReturDraftToPenjualan,setTipeHarga) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PENJUALAN'
    })
    axios.post(`${apiUrl.url}penjualan/transferfromdraft`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
    const ResponseApi = res.data;
    if(ResponseApi.message==="Stok tidak terpenuhi"){
        dispatch({
        type:'FAILED ACTION PENJUALAN',
        payload:"Penjualan gagal disimpan dikarenakan terdapat stok yang tidak terpenuhi,\nStok barang yang kosong (Kode Barang) "+ResponseApi.data[0].idbarang+" dengan selisih "+ResponseApi.data[0].selisih+" "+ResponseApi.data[0].satuan
        })
        setOpensnackbar(true);
    }else{
    dispatch({
        type:'SUCCESSED POST PENJUALAN',
        payload:ResponseApi.message,
        faktursave:ResponseApi.tempNewFaktur
    })
    transferReturDraftToPenjualan(data.fakturpenjualan,data.idpelanggan,data.tanggal);
    setTipeHarga("Harga Jual");
    formik.handleReset();
    setOpensnackbar(true);
    dispatch({
        type:'SET STATUS DRAFT DEFAULT',
        status:'Draft'
    })
    dispatch({
        type:'EMPTY CART PENJUALAN'
    })
        axios.get(`${apiUrl.url}penjualan/nofaktur/?tanggal=${data.tanggal}`,{
            headers:{
                "Authorization" : `Bearer ${token}`,
            }
        })
        .then(res=>{
            const ResponseAPI = res.data;
            dispatch({
                type:'SUCCESSED GET NO FAKTUR PENJUALAN',
                payload:ResponseAPI.data
            })
            if(statusSave==="SimpanPrint"){
                history.push(`/laporan/penjualan/${data.fakturpenjualan}`)
            }else if(statusSave==="Simpan"){
                history.push(`/penjualan/`)
                formik.setFieldValue('fakturpenjualan',ResponseAPI.data)
            }
        })
        .catch(error=>{
            dispatch({
            type:'FAILED ACTION PENJUALAN',
            payload:error.message
            })
            setOpensnackbar(true);
        })
        }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const transferWithRetur = (data,token,setOpensnackbar,formik,history,statusSave,returPenjualan,setTipeHarga) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PENJUALAN'
    })
    axios.post(`${apiUrl.url}penjualan/transferfromdraft`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        
    const ResponseApi = res.data;
    dispatch({
        type:'SUCCESSED POST PENJUALAN',
        payload:ResponseApi.message,
        faktursave:ResponseApi.tempNewFaktur
    })
    returPenjualan(data.fakturpenjualan,data.idpelanggan,data.tanggal);
    setTipeHarga("Harga Jual");
    formik.handleReset();
    setOpensnackbar(true);
    dispatch({
        type:'SET STATUS DRAFT DEFAULT',
        status:'Draft'
    })
    dispatch({
        type:'EMPTY CART PENJUALAN'
    })
        axios.get(`${apiUrl.url}penjualan/nofaktur/?tanggal=${data.tanggal}`,{
            headers:{
                "Authorization" : `Bearer ${token}`,
            }
        })
        .then(res=>{
            const ResponseAPI = res.data;
            dispatch({
                type:'SUCCESSED GET NO FAKTUR PENJUALAN',
                payload:ResponseAPI.data
            })
            if(statusSave==="SimpanPrint"){
                history.push(`/laporan/penjualan/${data.fakturpenjualan}`)
            }else if(statusSave==="Simpan"){
                history.push(`/penjualan/`)
                formik.setFieldValue('fakturpenjualan',ResponseAPI.data)
            }
        })
        .catch(error=>{
            dispatch({
            type:'FAILED ACTION PENJUALAN',
            payload:error.message
            })
            setOpensnackbar(true);
        })
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const getNilaiPPNPenjualan = (token,setOpensnackbar) =>(dispatch)=>{
    axios.get(`${apiUrl.url}config/`,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'GET NILAI PPN PENJUALAN',
            payload:ResponseAPI.data[0].value
        })
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}