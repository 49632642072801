import React, { useEffect, useState } from 'react'
import {Container,Typography,styled, useTheme,Divider,Box, TextField, TableContainer, Table, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, Tooltip, IconButton, Snackbar} from '@mui/material';
import Cookies from 'js-cookie';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteData, EditData } from '../../../components/assets';
import { useHistory } from 'react-router-dom';
import { deletePelanggan, emptyPelangganMessage, getAllPelanggan, getDetailsPelanggan } from '../../../config/redux/actions';
import { DeleteDialog,TablePagination } from '../../../components/molecules';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'grey',
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const PelangganPage = () => {
    const theme = useTheme();
    const paperTable = {
        marginTop:'10px',
        [theme.breakpoints.up('sm')]:{
            width:'50%',
            height:'370px',
        }, 
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'360px'
        },
        scrollbarWidth:'thin'
    }
    const boxSearch = {
        [theme.breakpoints.up('sm')]:{
            display:'flex',
            justifyContent:'flex-end',
            width:'50%',
        }, 
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            justifyContent:'flex-end',
            width:'100%',
        }
    }
    const {dataPelanggan,pagePelanggan,pelangganMessage,isLoading} = useSelector(state=>state.pelangganReducers);
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const history = useHistory();
    const dispatch = useDispatch();
    const [token,setToken] = useState(dataLogin.token);
    const [search,setSearch] = useState('');
    const [counter,setCounter] = useState(1);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [openDialog,setOpenDialog]=useState(false);
    const [messageDialog,setMessageDialog]=useState('');
    const [tempIdPelanggan,setTempIdPelanggan] = useState('');
    const handleChangeSearch = (e) =>{
        setSearch(e.target.value);
        dispatch(getAllPelanggan(e.target.value,counter,token,setOpensnackbar));
    }
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        dispatch(getAllPelanggan(search,counter,token,setOpensnackbar));
    },[search,counter,token,dispatch])
    const handlePrev = () =>{
        setCounter(counter <= 1? 1 : counter - 1);
    }
    const handleNext = () =>{
        setCounter(counter === pagePelanggan.totalPage ? pagePelanggan.totalPage : counter + 1);
    }
    const handleEditPelanggan = (idpelanggan) =>{
        dispatch(getDetailsPelanggan(idpelanggan,token,setOpensnackbar));
        history.push(`/pelanggan/ubah/${idpelanggan}`);
    }
    const handleOpenDialog = (idpelanggan,nama)=>{
        setMessageDialog(`Apakah anda yakin akan menghapus data ${nama}(${idpelanggan})?`);
        setTempIdPelanggan(idpelanggan);
        setOpenDialog(true);
    }
    const handleCloseDialog = ()=>{
        setOpenDialog(false);
    }
    
    const handleDeletePelanggan = () =>{
        dispatch(deletePelanggan(tempIdPelanggan,token,setOpensnackbar,search,counter,setOpenDialog));
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptyPelangganMessage(''));
        }, 100);
    }
    const tablePelanggan = (
        <TableBody>
            {dataPelanggan.map((pelanggan) => (
                <StyledTableRow key={pelanggan.idpelanggan}>
                <StyledTableCell width={120} align="center">
                    {pelanggan.nama}
                </StyledTableCell>
                <StyledTableCell width={120} align="center">{pelanggan.alamat}</StyledTableCell>
                <StyledTableCell width={120} align="center">{pelanggan.notlp}</StyledTableCell>
                <StyledTableCell width={60} align="center">
                    <Tooltip title="Ubah data" onClick={()=>handleEditPelanggan(pelanggan.idpelanggan)}>
                        <IconButton>
                            <img src={EditData} alt="editData"/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Hapus data">
                        <IconButton onClick={()=>handleOpenDialog(pelanggan.idpelanggan,pelanggan.nama)}>
                            <img src={DeleteData} alt="deleteData"/>
                        </IconButton>
                    </Tooltip>
                </StyledTableCell>
                </StyledTableRow>
            ))}
        </TableBody>
    )
    return (
        <Container maxWidth='xl' sx={{marginTop:'100px'}}>
            <Typography variant='h6' pl={1} mt={1}>Data Pelanggan</Typography>
            <Divider/>
            <Box sx={{marginTop:'5px',width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <Box sx={boxSearch}>
                <TextField
                id='search'
                name='search'
                label='Cari Pelanggan'
                variant='standard'
                onChange={handleChangeSearch}
                />
            </Box>
            <TableContainer component={Paper} sx={paperTable}>
                <Table stickyHeader aria-label="customized table" size='small'>
                    <TableHead>
                    <TableRow>
                        <StyledTableCell width={120} align="center">Nama</StyledTableCell>
                        <StyledTableCell width={120} align="center">Alamat</StyledTableCell>
                        <StyledTableCell width={120} align="center">No Handphone</StyledTableCell>
                        <StyledTableCell width={60} align="center"/>
                    </TableRow>
                    </TableHead>
                        {tablePelanggan} 
                </Table>
            </TableContainer>
            <Box sx={boxSearch}>
            <TablePagination
            page={counter}
            totalPage={pagePelanggan.totalPage >= 1 ? pagePelanggan.totalPage : pagePelanggan.currentPage}
            handlePrev={handlePrev}
            handleNext={handleNext}
            hiddenButton={false}
            onButtonClick={()=>history.push('/pelanggan/baru')}
            />
            </Box>
            </Box>
            <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={pelangganMessage === 'Penghapusan data pelanggan berhasil'? 'success':'error'}
                >
                    {pelangganMessage}
                    </Alert>
            </Snackbar>
            <DeleteDialog 
            openDialog={openDialog} 
            message={messageDialog}
            handleCloseDialog={handleCloseDialog}
            handleClick={handleDeletePelanggan}
            isLoading={isLoading}
            textHapus={"Ya"}
            textKeluar={"Tidak"}/>
        </Container>
    )
}

export default PelangganPage
