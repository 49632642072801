import React, { useEffect, useState } from 'react'
import {Box, Container, useTheme} from '@mui/material';
import {UserMenuIcon,SupplierMenuIcon,CustomerMenuIcon,ItemMenuIcon,StokbarangMenuIcon,SatuanMenuIcon, PembelianMenuIcon, PPNMenuIcon, HargaMenuIcon, DaftarHargaMenuIcon, ReturMenuIcon, PenjualanMenuIcon} from '../../components/assets';
import { CardMenu } from '../../components/molecules';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { getDetailsPengguna } from '../../config/redux/actions';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const MenuPage = () => {
    const theme = useTheme();
    const container ={
        [theme.breakpoints.up('sm')]:{
            width:'90%',
            height:'100vh',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            marginLeft:'2%',
            marginRight:'2%'
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            marginTop:'80px'
        }
    }
    const paperList={
        [theme.breakpoints.up('sm')]:{
            width:'1050px',
            height:'140px',
            display:'flex',
            justifyContent:'center',
            marginTop:'20px',
        },
        [theme.breakpoints.down('sm')]:{
            width:'150px',
            height:'900px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            marginTop:'20px'
        }
    }
    const {dataLogin,detailsPengguna} =useSelector(state=>state.penggunaReducers);
    const [uid,setUid]  = useState(dataLogin.u_name);
    const [token,setToken] = useState(dataLogin.token);
    const [Pengguna,setPengguna] = useState(false);
    const [Pelanggan,setPelanggan] = useState(false);
    const [Distributor,setDistributor] = useState(false);
    const [StokBarang,setStokBarang] = useState(false);
    const [Barang,setBarang] = useState(false);
    const [Harga,setHarga] = useState(false);
    const [Pembelian,setPembelian] = useState(false);
    const [Penjualan,setPenjualan] = useState(false);
    const [Retur,setRetur] = useState(false);
    const [Laporan,setLaporan] = useState(false);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
        setUid(Cookies.get('u_name'));
    }
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(()=>{
        if(detailsPengguna===""){
            dispatch(getDetailsPengguna(uid,token,"",history));
        }
        detailsPengguna.m_pengguna === 'true' ? setPengguna(false) : setPengguna(true);
        detailsPengguna.m_pelanggan === 'true' ? setPelanggan(false) : setPelanggan(true);
        detailsPengguna.m_distributor === 'true' ? setDistributor(false) : setDistributor(true);
        detailsPengguna.m_stokbarang === 'true' ? setStokBarang(false) : setStokBarang(true);
        detailsPengguna.m_barang === 'true' ? setBarang(false) : setBarang(true);
        detailsPengguna.m_harga === 'true' ? setHarga(false) : setHarga(true);
        detailsPengguna.m_pembelian === 'true' ? setPembelian(false) : setPembelian(true);
        detailsPengguna.m_penjualan === 'true' ? setPenjualan(false) : setPenjualan(true);
        detailsPengguna.m_retur === 'true' ? setRetur(false) : setRetur(true);
        detailsPengguna.m_laporan === 'true' ? setLaporan(false) : setLaporan(true);
    },[dispatch,uid,token,detailsPengguna])
    return (
        <Container component='div' maxWidth='xl' sx={container}>
            <Box sx={paperList}>
                <Link 
                to={Pengguna === false ? "/pengguna" :"/"}
                style={{textDecoration:'none',color:'black'}}>
                    <CardMenu disabled={Pengguna} cardIcon={UserMenuIcon} cardLabel="Pengguna" altIcon="userMenuIcon"/>
                </Link>
                <Link 
                to={Distributor === false ? "/distributor" :"/"}
                style={{textDecoration:'none',color:'black'}}>
                    <CardMenu disabled={Distributor} cardIcon={SupplierMenuIcon} cardLabel="Distributor" altIcon="supplierMenuIcon"/>
                </Link>
                <Link 
                to={Pelanggan === false ? "/pelanggan" :"/"}
                style={{textDecoration:'none',color:'black'}}>
                    <CardMenu disabled={Pelanggan} cardIcon={CustomerMenuIcon} cardLabel="Pelanggan" altIcon="customerMenuIcon"/>
                </Link>
                <Link 
                to={Barang === false ? "/barang" :"/"}
                style={{textDecoration:'none',color:'black'}}>
                    <CardMenu disabled={Barang} cardIcon={ItemMenuIcon} cardLabel="Barang" altIcon="itemMenuIcon"/>
                </Link>
                <Link 
                to={Harga === false ? "/hargabarang" :"/"}
                style={{textDecoration:'none',color:'black'}}>
                    <CardMenu disabled={Harga} cardIcon={HargaMenuIcon} cardLabel="Harga" altIcon="hargaMenuIcon"/>
                </Link>
                <Link 
                to={Barang === false ? "/satuan" :"/"}
                style={{textDecoration:'none',color:'black'}}>
                    <CardMenu disabled={Barang} cardIcon={SatuanMenuIcon} cardLabel="Satuan" altIcon="satuanMenuIcon"/>
                </Link>
            </Box>
            <Box sx={paperList}>
                <Link 
                to={Pembelian === false ? "/pembelian" :"/"}
                style={{textDecoration:'none',color:'black'}}>
                    <CardMenu disabled={Pembelian} cardIcon={PembelianMenuIcon} cardLabel="Pembelian" altIcon="pembelianMenuIcon"/>
                </Link>
                <Link 
                to={StokBarang === false ? "/stokbarang" :"/"}
                 style={{textDecoration:'none',color:'black'}}>
                    <CardMenu disabled={StokBarang} cardIcon={StokbarangMenuIcon} cardLabel="Stok Barang" altIcon="stokbarangMenuIcon"/>
                </Link>    
                <Link 
                to={Penjualan === false ? "/penjualan" : "/"}
                style={{textDecoration:'none',color:'black'}}>
                    <CardMenu disabled={Penjualan} cardIcon={PenjualanMenuIcon} cardLabel="Penjualan" altIcon="penjualanMenuIcon"/>
                </Link> 
                <Link 
                to={Retur === false ? "/stokretur" : "/"}
                style={{textDecoration:'none',color:'black'}}>
                    <CardMenu disabled={Retur} cardIcon={ReturMenuIcon} cardLabel="Stok Retur" altIcon="stokreturMenuIcon"/>
                </Link> 
                {detailsPengguna.hakakses === 'Pemilik' ? 
                <Link to="/ppn" style={{textDecoration:'none',color:'black'}}>
                    <CardMenu cardIcon={PPNMenuIcon} cardLabel="PPN" altIcon="ppnMenuIcon"/>
                </Link> : null }
                {detailsPengguna.hakakses === 'Pemilik' ? 
                <Link 
                to={Laporan===false?"/laporan":"/"} 
                disabled={Laporan} style={{textDecoration:'none',color:'black'}}>
                    <CardMenu cardIcon={DaftarHargaMenuIcon} cardLabel="Laporan" altIcon="laporanMenuIcon"/>
                </Link> : null }
            </Box>
        </Container>
    )
}

export default MenuPage
