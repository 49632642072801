import React, { useEffect, useState } from 'react'
import {Container,Typography,Divider,styled, useTheme,Box,TableContainer, Table, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, Snackbar, TextField, Tooltip, IconButton} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { TablePagination } from '../../../components/molecules';
import { getStokRetur, emptyStokReturMessage } from '../../../config/redux/actions';
import { EditData } from '../../../components/assets';
import UbahStokReturDialog from '../UbahStokReturDialog';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'grey',
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const StokReturPage = () => {
    const theme = useTheme();
    const paperTable = {
        marginTop:'10px',
        [theme.breakpoints.up('sm')]:{
            width:'60%',
            height:'400px',
        }, 
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'360px'
        },
        scrollbarWidth:'thin'
    }
    const boxSearch = {
        [theme.breakpoints.up('sm')]:{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'flex-end',
            width:'60%',
        }, 
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'flex-end',
            width:'100%',
        }
    }
    const textBoxField={
        [theme.breakpoints.up('sm')]:{
            width:'60%'
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%'
        },
        justifyContent:'right',
        display:'flex',
        alignItems:'flex-end',
        border:'1px',
        marginBottom:'2px',
      }
    const textBoxSearch = {
        width:'200px'
    }
    const {dataLogin,detailsPengguna} =useSelector(state=>state.penggunaReducers);
    const {pageStokRetur,dataStokRetur,stokreturMessage} =useSelector(state=>state.stokreturReducers);
    const dispatch = useDispatch();
    const [token,setToken] = useState(dataLogin.token);
    const [counter,setCounter] = useState(1);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [search,setSearch]=useState("");
    const [openDialog,setOpenDialog]=useState(false);
    const [tempStokRetur,setTempStokRetur]=useState({
        'idbarang':'',
        'idsatuan':'',
        'namabarang':'',
        'satuan':'',
        'jumlah':'',
        'search':'',
        'page':''
    });
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        dispatch(getStokRetur(search,counter,token,setOpensnackbar));
    },[search,counter,token,dispatch])
    const handlePrev = () =>{
        setCounter(counter <= 1? 1 : counter - 1);
    }
    const handleNext = () =>{
        setCounter(counter === pageStokRetur.totalPage ? pageStokRetur.totalPage : counter + 1);
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptyStokReturMessage(''));
        }, 100);
    }
    const handleEditStokRetur = (idbarang,idsatuan,namabarang,satuan,jumlah)=>{
        setOpenDialog(true);
        setTempStokRetur({
            'idbarang':idbarang,
            'idsatuan':idsatuan,
            'namabarang':namabarang,
            'satuan':satuan,
            'jumlah':jumlah,
            'search':search,
            'page':counter
        })
    }
    const closeEditStokRetur = () =>{
        setOpenDialog(false);
        dispatch(getStokRetur(search,counter,token,setOpensnackbar));
    }
    const tableStokRetur = (
        <TableBody>
            {dataStokRetur.map((stokreturs) => (
                <StyledTableRow key={stokreturs.idstokretur}>
                <StyledTableCell width={120} align="center">{stokreturs.idbarang}</StyledTableCell>
                <StyledTableCell width={200} align="center">{stokreturs.namabarang}</StyledTableCell>
                <StyledTableCell width={120} align="center">{stokreturs.satuan}</StyledTableCell>
                <StyledTableCell width={120} align="center">{stokreturs.qty}</StyledTableCell>
                <StyledTableCell width={50} align="center">
                    {detailsPengguna.hakakses === 'Pemilik' ? 
                    <Tooltip title="Ubah stok retur" >
                    <IconButton 
                         onClick={()=>handleEditStokRetur(stokreturs.idbarang,stokreturs.idsatuan,stokreturs.namabarang,stokreturs.satuan,stokreturs.qty)}
                        >
                            <img src={EditData} alt="editData"/>
                        </IconButton>
                    </Tooltip> : 
                    <IconButton 
                        disabled={true}
                        >
                            <img src={EditData} alt="editData"/>
                        </IconButton>
                    }
                
                </StyledTableCell>
                </StyledTableRow>
            ))}
        </TableBody>
    )
    const handleChangeSearch = (e) =>{
        setSearch(e.target.value);
        dispatch(getStokRetur(e.target.value,counter,token,setOpensnackbar));
    }
  return (
     <Container maxWidth='xl' sx={{marginTop:'100px'}}>
        <Typography variant='h6' pl={1} mt={1}>Data Stok Retur</Typography>
        <Divider/>
        <Box sx={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
        <Box sx={textBoxField}>
                <TextField
                sx={textBoxSearch}
                id='search'
                name='search'
                value={search}
                label='ID Barang / Nama Barang'
                variant='standard'
                onChange={(e)=>handleChangeSearch(e)}
                />
          </Box>
        <TableContainer component={Paper} sx={paperTable}>
                <Table stickyHeader aria-label="customized table" size='small'>
                    <TableHead>
                    <TableRow>
                        <StyledTableCell width={120} align="center">ID Barang</StyledTableCell>
                        <StyledTableCell width={200} align="center">Nama Barang</StyledTableCell>
                        <StyledTableCell width={120} align="center">Satuan</StyledTableCell>
                        <StyledTableCell width={120} align="center">Qty</StyledTableCell>
                        <StyledTableCell width={50} align="center"></StyledTableCell>
                    </TableRow>
                    </TableHead>
                        {tableStokRetur} 
                </Table>
            </TableContainer>
            <Box sx={boxSearch}>
                <TablePagination
                    page={counter}
                    totalPage={pageStokRetur.totalPage >= 1 ? pageStokRetur.totalPage : pageStokRetur.currentPage}
                    handlePrev={handlePrev}
                    handleNext={handleNext}
                    hiddenButton={true}
                />
            </Box>
            </Box>
            <Snackbar 
            open={openSnackbar} 
            autoHideDuration={1000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={stokreturMessage === 'Perubahan password berhasil' ? "success" : "error"}
                >
                    {stokreturMessage}
                    </Alert>
            </Snackbar>
            <UbahStokReturDialog openDialogUbah = {openDialog} closeDialogUbah={closeEditStokRetur} tempDataRetur={tempStokRetur}/>
    </Container>
  )
}

export default StokReturPage