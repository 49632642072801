import { Button, CircularProgress, Divider, Paper,Snackbar,TextField,IconButton, Typography} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { loginIcon } from '../../assets';
import { Box } from '@mui/system';
import {useDispatch, useSelector} from 'react-redux';
import { getCountUser, loginUser,emptyUserMessage } from '../../../config/redux/actions/penggunaActions';
import {useHistory} from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import MuiAlert from '@mui/material/Alert';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const LoginCard = () => {
    const sytlePaper={
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        width:'320px',
        height:'360px',
        borderRadius:'15px',
        backgroundColor:'#edeefa',
        opacity:0.95,
        border:'0.5px solid grey'
    }
    const dispatch = useDispatch();
    const {totalUser,isLoading,penggunaMessage} = useSelector(state=>state.penggunaReducers);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [showPassword,setShowPassword]=useState(false);
    useEffect(()=>{
        if(totalUser === ''){
            dispatch(getCountUser());
        }
    },[totalUser,dispatch])
    const history = useHistory();
    const handleClickDaftar = () =>{
        history.push('/SignUp');
    }
    const validationSchema = yup.object().shape({
        idpengguna:yup.string().required("ID Pengguna tidak boleh kosong"),
        password:yup.string().required("Kata sandi tidak boleh kosong")
    })
    const formik = useFormik({
        initialValues:{
            idpengguna:'',
            password:''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('idpengguna',values.idpengguna);
            data.append('password',values.password);
            dispatch(loginUser(data,history,setOpensnackbar));
        }
    })
    const disabledButton = (totalUser) =>{
        if(totalUser !== "" && isLoading === false){
            return false;
        }else if(totalUser !== "" && isLoading === true){
            return true;
        }else if(totalUser === "" && isLoading === false){
            return true;
        }else if(totalUser === "" && isLoading === true){
            return true;
        }
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptyUserMessage(''));
        }, 100);
    }
    const handleClickShowPassword = () =>{
        setShowPassword(!showPassword);
    }
    const handleEnterPassword = (e) =>{
        if(e.key==="Enter"){
            formik.handleSubmit();
        }
    }
    return (
        <div>
            <Paper sx={sytlePaper} elevation={3}>
                <img src={loginIcon} width='100px' height="100px" alt='loginIcon' style={{paddingTop:'5px'}}/>
                <Box width='100%' mt={3} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                <Divider style={{width:'100%'}}/>
                <TextField
                    id="idpengguna"
                    label="ID Pengguna"
                    variant="standard"
                    style={{width:'80%',marginTop:'2px'}}
                    disabled={totalUser===""?true:false}
                    value={formik.values.idpengguna}
                    error={formik.touched.idpengguna && Boolean(formik.errors.idpengguna)}
                    helperText={formik.touched.nama && formik.errors.nama}
                    onChange={formik.handleChange}
                    />
                    <Box sx={{width:'80%',display:'flex',alignItems:'flex-end',marginTop:'2px'}}>
                        <TextField
                        id="password"
                        label="Kata Sandi"
                        type={showPassword ? 'text':'password'}
                        variant="standard"
                        style={{width:'85%'}}
                        disabled={totalUser===""?true:false}
                        value={formik.values.password}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        onChange={formik.handleChange}
                        onKeyDown={handleEnterPassword}
                        />
                        <IconButton 
                        arial-label='toggle-password'
                        onClick={handleClickShowPassword}
                        >
                        {showPassword?<Visibility/>:<VisibilityOff/>}
                        </IconButton>
                    </Box>
                    
                    {totalUser >= 1 ? 
                        <Button style={{marginTop:'20px',width:'60%'}} variant='contained' color='primary' onClick={formik.handleSubmit} disabled={disabledButton(totalUser)}>{isLoading === true ? <CircularProgress color='primary' size={25}/> : "LOGIN"}</Button> :
                        <Button style={{marginTop:'20px',width:'60%'}} variant='contained' color='success' onClick={handleClickDaftar} disabled={totalUser===""?true:false}>DAFTAR</Button>
                        }
                    <Box display='flex' width='100%' height='70px' pr='20px' justifyContent='flex-end' alignItems='flex-end'>
                        <Typography variant='caption' style={{fontFamily:'cursive'}}>Toko Jaya Makmur</Typography>
                    </Box>
                </Box>
                <Snackbar 
                open={openSnackbar} 
                autoHideDuration={6000} 
                onClose={handleCloseSnackbar}
                anchorOrigin={{
                    vertical:'bottom',
                    horizontal:'center'
                }}>
                    <Alert 
                    onClose={handleCloseSnackbar}
                    severity={penggunaMessage === 'Login berhasil'? 'success':'error'}
                    >
                        {penggunaMessage}
                        </Alert>
                </Snackbar>
            </Paper>
        </div>
    )
}

export default LoginCard
