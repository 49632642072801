import { Box, Checkbox, Container,styled,TableContainer,Table,TableHead,TableRow,Paper, TableCell, tableCellClasses, TableBody, Divider, TextField, Typography, useTheme,IconButton, Snackbar, Button, Tooltip, Switch, Autocomplete } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDistributorForCombo,getFakturPembelian,emptyfaktuPembelian, emptyListBarang, setMessagePembelian, deleteItemPembelian, setEmptyListDiskonTotal, getNilaiPPN, emptyNilaiPPN, editNilaiPPN, postPembelian, emptyCartRetur, postRetur, emptyListFromExcel, editStokRetur } from '../../../config/redux/actions';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import * as yup from 'yup';
import Cookies from 'js-cookie';
import { useFormik } from 'formik';
import {add,format} from 'date-fns'
import { AddTodo, DeleteData, EditStok } from '../../../components/assets';
import { BarangBaruDialog, BarangListDialog, DiskonBarangDialog, ImportExcelDialog } from '../../../components/molecules';
import MuiAlert from '@mui/material/Alert';
import ReturPembelian from '../../ModulRetur/ReturPembelian';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'grey',
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const PembelianPage = () => {
    const theme = useTheme();
    const valuePembayaran = [
          {
            value: 'Tunai',
            label: 'Tunai',
          },
          {
            value: 'Kredit',
            label: 'Kredit',
          }
    ];
    const asalRetur = [
        {
          value: 'Stok Toko',
          label: 'Stok Toko',
        },
        {
          value: 'Stok Retur',
          label: 'Stok Retur',
        }
    ];
    const boxWapper = {
    display:'flex',
    flexDirection:'column',
    // justifyContent:'center',
    [theme.breakpoints.up('sm')]:{
        width:'80%',
        maxHeight:'500px',
        height:'100%',
    },
    [theme.breakpoints.down('lg')]:{
        width:'100%',
        height:'100%',
    }
}
const paperInput={
    display:'flex',
    justifyContent:'space-between',
    marginTop:'10px',
    [theme.breakpoints.up('sm')]:{
        width:"100%",
        height:'100%',
        borderRadius:'10px',
        marginTop:'10px'
    },
    [theme.breakpoints.down('sm')]:{
        width:'100%',
        height:'100%',
        borderRadius:'10px',
        marginTop:'10px'
    }
}
const pembelianInput={
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    [theme.breakpoints.up('lg')]:{
        width:"100%",
        height:'100%',
        borderRadius:'10px',
        marginTop:'10px'
    },
    [theme.breakpoints.down('lg')]:{
        width:'100%',
        height:'100%',
        borderRadius:'10px',
        marginTop:'10px'
    }
}
const fieldInput ={
    display:'flex',
    paddingLeft:'10px',
    alignItems:'flex-end',
    // border:'1px solid black',
    [theme.breakpoints.up('lg')]:{
        width:"90%",
        height:'100%',
        borderRadius:'10px',
        marginTop:'10px'
    },
    [theme.breakpoints.down('lg')]:{
        width:'90%',
        height:'100%',
        borderRadius:'10px',
        marginTop:'10px'
    }
}
const paperTable = {
        marginTop:'10px',
        [theme.breakpoints.up('sm')]:{
            width:'90%',
            height:'350px',
        }, 
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'360px'
        },
        scrollbarWidth:'thin'
    }
const {dataComboDistributor}=useSelector(state=>state.distributorReducers);
const {dataLogin} =useSelector(state=>state.penggunaReducers);
const {timeConnection} = useSelector(state=>state.homeReducers);
const {fakturPembelian,pembelianMessage,cartPembelian,listDiskonTotal,ppn,isLoading} = useSelector(state=>state.pembelianReducers);
const {cartRetur}=useSelector(state=>state.returReducers);
const [token,setToken] = useState(dataLogin.token);
const [openSnackbar,setOpensnackbar]=useState(false);
const [disableFaktur,setDisableFaktur]=useState(false);
const [autoId,setAutoId]=useState(false);
const [tempo,setTempo]=useState(true);
const [valueID,setValueID]=useState('');
const [openDialog,setOpenDialog]=useState(false);
const [namaDist,setNamaDist]=useState('');
const [openBarangBaru,setOpenBarangBaru]=useState(false);
const [subtotal,setSubtotal]=useState(0);
const [labelSwitch,setLabelSwitch] =useState('Tanpa PPN');
const [openDiskonDialog,setOpenDiskonDialog]=useState(false);
const [buttonDiskon,setButtonDiskon] = useState(false);
const [diskonTotal,setDiskonTotal]=useState(0);
const [checkSwitch,setCheckSwitch] = useState(false);
const [checkPPN,setCheckPPN] = useState(false);
const [disabledPPN,setDisabledPPN]=useState(true);
const [totalDPP,setTotalDPP]=useState(0);
const [totalPPN,setTotalPPN]=useState(0);
const [grandTotal,setGrandTotal]=useState(0);
const [checkRetur,setCheckRetur]=useState(false);
const [disabledAsalRetur,setDisabledAsalRetur] = useState(true);
const [asalReturText,setAsalReturText]=useState('');
const [openExcelDialog,setOpenExcelDialog]=useState(false);
const [statusDialog,setStatusDialog] = useState('barang');

const dispatch = useDispatch();
if(token ===''){
    setToken(Cookies.get('u_tkn'));
}
const validationSchema = yup.object().shape({
    fakturpembelian:yup.string().required("Faktur pembelian tidak boleh kosong"),
    tanggal:yup.date().required().max(new Date(),"Tanggal transaksi pembelian tidak boleh lebih besar dari hari ini").min(new Date(1990,1,1),"Tanggal tidak valid"),
    iddistributor:yup.string().required("Pilih kembali distributor"),
    pembayaran:yup.string().required("Jenis pembayaran tidak boleh kosong"),
    jatuhtempo:yup.number().min(0,"Jatuh tempo tidak boleh kurang dari 0"),
    tanggalJatuhTempo:yup.date().when('tanggal',(tanggal,schema)=>(tanggal&&schema.min(tanggal)))
})
const formik=useFormik({
    initialValues:{
        fakturpembelian:fakturPembelian||'',
        tanggal:new Date(),
        iddistributor:dataComboDistributor.iddistributor||"",
        pembayaran:'',
        jatuhtempo:'',
        tanggalJatuhTempo:new Date()
    },
    validationSchema:validationSchema,
    onSubmit:(values)=>{
        let tempDiskonAll;
        let tempPPN;
        if(listDiskonTotal.length<1){
            tempDiskonAll='-';
        }else if(listDiskonTotal.length>0){
            tempDiskonAll = listDiskonTotal;
        }
        if(ppn===''){
            tempPPN=0;
        }else{
            tempPPN=ppn;
        }
        if(cartPembelian.length<1 && cartRetur.length<1){
            dispatch(setMessagePembelian("Daftar pembelian belum terisi"));
            setOpensnackbar(true);
        }else if(cartPembelian.length<1 && cartRetur.length>0){
            dispatch(setMessagePembelian("Tidak dapat melakukan retur tanpa pembelian"));
            setOpensnackbar(true);
        }else if(cartPembelian.length>0){
            if(checkPPN===true && checkSwitch===true){
                const data = {
                    'fakturpembelian':values.fakturpembelian,
                    'iddistributor':values.iddistributor,
                    'tanggal':format(values.tanggal,'yyyy-MM-dd'),
                    'jatuhtempo':format(values.tanggalJatuhTempo,'yyyy-MM-dd'),
                    'pembayaran':values.pembayaran,
                    'ketdiskon':tempDiskonAll,
                    'diskon':diskonTotal,
                    'ppn':tempPPN,
                    'kelompok':"PPN",
                    'total':grandTotal,
                    'status':'Berhasil',
                    'listdetails':cartPembelian,
                    'checkPPN':true,
                    'includePPN':true,
                    'totalDPP':totalDPP,
                    'totalPPN':totalPPN
                }
                dispatch(postPembelian(data,token,setOpensnackbar,formik,setAutoId,setDisableFaktur,setCheckPPN,setCheckSwitch,setLabelSwitch,setOpenDialog));
                setLabelSwitch("Tanpa PPN");
            }
            else if(checkPPN===true && checkSwitch===false){
                const data = {
                    'fakturpembelian':values.fakturpembelian,
                    'iddistributor':values.iddistributor,
                    'tanggal':format(values.tanggal,'yyyy-MM-dd'),
                    'jatuhtempo':format(values.tanggalJatuhTempo,'yyyy-MM-dd'),
                    'pembayaran':values.pembayaran,
                    'ketdiskon':tempDiskonAll,
                    'diskon':diskonTotal,
                    'ppn':ppn,
                    'kelompok':"PPN",
                    'total':grandTotal,
                    'status':'Berhasil',
                    'listdetails':cartPembelian,
                    'checkPPN':true,
                    'includePPN':false,
                    'totalDPP':totalDPP,
                    'totalPPN':totalPPN
                }
                dispatch(postPembelian(data,token,setOpensnackbar,formik,setAutoId,setDisableFaktur,setCheckPPN,setCheckSwitch,setLabelSwitch,setOpenDialog));
            }
            else if(checkPPN===false && checkSwitch===false){
                const data = {
                    'fakturpembelian':values.fakturpembelian,
                    'iddistributor':values.iddistributor,
                    'tanggal':format(values.tanggal,'yyyy-MM-dd'),
                    'jatuhtempo':format(values.tanggalJatuhTempo,'yyyy-MM-dd'),
                    'pembayaran':values.pembayaran,
                    'ketdiskon':tempDiskonAll,
                    'diskon':diskonTotal,
                    'ppn':ppn,
                    'kelompok':"NON PPN",
                    'total':grandTotal,
                    'status':'Berhasil',
                    'listdetails':cartPembelian,
                    'checkPPN':false,
                    'includePPN':false,
                    'totalDPP':0,
                    'totalPPN':0
                }
                dispatch(postPembelian(data,token,setOpensnackbar,formik,setAutoId,setDisableFaktur,setCheckPPN,setCheckSwitch,setLabelSwitch,setOpenDialog));
            }
            if(cartRetur.length>0){
                let fromRetur = cartRetur.filter(retur => retur.ref === "Stok Retur");
                if(fromRetur.length>0){
                    const dataList = {
                        'listEditRetur':fromRetur
                    }
                    dispatch(editStokRetur(dataList,token,setOpensnackbar));
                }
                let tempTotalRetur=cartRetur.reduce((Retur,TotalR)=>{
                    return Retur + TotalR.jumlah
                },0);
                const data={
                    'iddistributor':values.iddistributor,
                    'fakturpembelian':values.fakturpembelian,
                    'tanggal':format(values.tanggal,'yyyy-MM-dd'),
                    'total':tempTotalRetur,
                    'status':"Berhasil",
                    'listReturDetails':cartRetur
                }
                dispatch(postRetur(data,token));
            }
        }
    }
})
useEffect(()=>{
    if(dataComboDistributor.length<1){
        dispatch(getAllDistributorForCombo("",token,setOpensnackbar));
    }
    if(cartPembelian.length>0){
        let totalPembelian=cartPembelian.reduce((Pembelian,TotalP)=>{
            return Pembelian + TotalP.jumlah
        },0);
        setSubtotal(totalPembelian);
        setButtonDiskon(false);
    }else{
        setSubtotal(0);
        setButtonDiskon(true);
    }
    if(listDiskonTotal.length>0){
        let tempDiskon=0;
        let SubTempDiskon=0;
        let tempSubtotal=subtotal;
        listDiskonTotal.forEach(dis => {
            if(dis.jenisdiskon === '%'){
                SubTempDiskon = tempSubtotal * parseFloat(dis.diskon) / 100;
                tempDiskon=tempDiskon+SubTempDiskon;
                tempSubtotal=tempSubtotal-SubTempDiskon;
            }else if(dis.jenisdiskon === 'Rp'){
                tempDiskon=parseInt(tempDiskon)+parseInt(dis.diskon);
            }
        });
        setDiskonTotal(tempDiskon);
    }else if(listDiskonTotal.length<1){
        setDiskonTotal(0);
    }
    if(checkSwitch===true&&checkPPN===true&&ppn!==""){
        setLabelSwitch(ppn);
    }
    if(checkPPN===true && checkSwitch===true){
        setTotalDPP(0);
        let tempTotalPembelian = subtotal-diskonTotal;
        let tempTotalDPP = parseFloat(100/(100+parseInt(ppn)))*tempTotalPembelian;
        setTotalDPP(tempTotalDPP);
        let tempTotalPPN = tempTotalDPP * ppn/100;
        setTotalPPN(tempTotalPPN);
        let tempGrandTotal = tempTotalDPP+tempTotalPPN;
        setGrandTotal(tempGrandTotal);
    }else if(checkPPN===true && checkSwitch===false){
        setTotalDPP(0);
        let tempTotalDPP = subtotal-diskonTotal;
        setTotalDPP(tempTotalDPP);
        let tempTotalPPN = tempTotalDPP * ppn/100;
        setTotalPPN(tempTotalPPN);
        let tempGrandTotal = tempTotalDPP+tempTotalPPN;
        setGrandTotal(tempGrandTotal);
    }
    else if(checkPPN===false && checkSwitch===false){
        setTotalDPP(0);
        let tempTotalDPP = subtotal-diskonTotal;
        setTotalDPP(tempTotalDPP);
        let tempTotalPPN = 0;
        setTotalPPN(0);
        let tempGrandTotal = tempTotalDPP+tempTotalPPN;
        setGrandTotal(tempGrandTotal);
    }
},[token,dispatch,subtotal,grandTotal,diskonTotal,checkPPN,checkSwitch,ppn,cartPembelian,listDiskonTotal,dataComboDistributor])
const handleChangeChackeAutoId = (e) =>{
    setAutoId(!autoId);
    setDisableFaktur(e.target.checked);
    if(e.target.checked===false){
        dispatch(emptyfaktuPembelian());
        formik.setFieldValue('fakturpembelian',"");
    }else if(e.target.checked===true){
        dispatch(getFakturPembelian(format(formik.values.tanggal,'yyyy-MM-dd'),token,formik));
    }
}
const onSelectPembayaranHandleChange = (e)=>{
    if(e.target.value==="Tunai"){
        setTempo(true);
        formik.setFieldValue('pembayaran',e.target.value);
        formik.setFieldValue('jatuhtempo',0);
        formik.setFieldValue('tanggalJatuhTempo',new Date());
    }else if(e.target.value==="Kredit"){
        formik.setFieldValue('pembayaran',e.target.value);
        setTempo(false);
        formik.setFieldValue('jatuhtempo',0);
        formik.setFieldValue('tanggalJatuhTempo',new Date());
    }
}
const handleEnter = (e) =>{
    if(e.key === 'Enter'){
        if(formik.values.jatuhtempo<=0){
            formik.setFieldValue('tanggalJatuhTempo',new Date());
        }else if(formik.values.jatuhtempo>0){
            let tempTanggal = add(new Date(formik.values.tanggalJatuhTempo),{days:parseInt(formik.values.jatuhtempo)});
            formik.setFieldValue('tanggalJatuhTempo',tempTanggal);
        }
    }
}
const handleChangeTanggal = (e)=>{
    formik.setFieldValue('tanggal',e);
    if(disableFaktur===true){
        dispatch(getFakturPembelian(format(e,'yyyy-MM-dd'),token,formik));
    }
}
// const handleChangeDistributor = (e) => {
//     formik.setFieldValue('iddistributor',e.target.value);
//     setValueID(e.target.value);
//     setNamaDist(e.target.options[e.target.selectedIndex].text);
// };
const handleOpenDialog = (e) => {
    if(valueID === ''){
        dispatch(setMessagePembelian("Data distributor belum dipilih"));
        setOpensnackbar(true);
        setStatusDialog("");
    }else if(valueID!==""){
        dispatch(emptyListBarang());
        setOpenDialog(true);
        setStatusDialog("barang");
    }
};
const handleCloseDialog = () =>{
    setOpenDialog(false);
}
const handleOpenBarangbaru = (e) => {
    if(valueID === ''){
        dispatch(setMessagePembelian("Data distributor belum dipilih"));
        setOpensnackbar(true);
    }else if(valueID!==""){
        setOpenBarangBaru(true);
    }
};
const handleCloseBarangbaru = () =>{
    setOpenBarangBaru(false);
}
const handleCloseSnackbar = (event,reason) =>{
    if(reason === 'clickaway'){
        return;
    }
    setOpensnackbar(false);
    setTimeout(() => {
        dispatch(setMessagePembelian(''));
        setCheckRetur(false);
        setAsalReturText("");
        setStatusDialog("");
    }, 100);
}
const handleClickDeletePembelian = (id) =>{
    dispatch(deleteItemPembelian(id));
}
const handleChangeSwitch = (e) =>{
    if(checkPPN===true){
        setCheckSwitch(!checkSwitch);
        if(e.target.checked===true){
            dispatch(getNilaiPPN(token,setOpensnackbar));
            setLabelSwitch(ppn);
        }else if(e.target.checked===false){
            setLabelSwitch("Tanpa PPN");
        }
    }
}
const handleOpenDiskonDialog = () =>{
    setOpenDiskonDialog(true);
}
const handleCloseDiskonDialog = () =>{
    setOpenDiskonDialog(false);
}
const handleClearDiskon = () =>{
    dispatch(setEmptyListDiskonTotal());
}
const handleCheckPPN = (e) =>{
    setCheckPPN(!checkPPN);
    if(e.target.checked === true){
        setCheckSwitch(true);
        dispatch(getNilaiPPN(token,setOpensnackbar));
        setLabelSwitch(ppn);
    }else if(e.target.checked===false){
        setCheckSwitch(false);
        dispatch(emptyNilaiPPN(0));
        setLabelSwitch("Tanpa PPN");
    }
}
const handleClickEditPPN = () =>{
    setDisabledPPN(false);
}
const handleChangeValuePPN = (e) =>{
    setLabelSwitch(e.target.value);
}
const onEnterEditPPN = (e)=>{
    if(e.key === 'Enter'){
        dispatch(editNilaiPPN(labelSwitch,token));
        setDisabledPPN(true);
    }
}
const handleCheckRetur = (e) =>{
    if(formik.values.iddistributor===""){
        dispatch(setMessagePembelian("Data distributor belum dipilih"));
        setOpensnackbar(true);
        setDisabledAsalRetur(true);
    }else if(formik.values.iddistributor!==""){
        setCheckRetur(!checkRetur);
        if(e.target.checked===true){
            setDisabledAsalRetur(false);
        }else if(e.target.checked===false){
            setAsalReturText("");
            dispatch(emptyCartRetur());
        }
    }
}
const handleChangeAsalRetur = (e) =>{
    setAsalReturText(e.target.value);
}
const handleOpenExcelDialog = () =>{
    if(valueID === ''){
        dispatch(setMessagePembelian("Data distributor belum dipilih"));
        setOpensnackbar(true);
    }else if(valueID!==''){
        setOpenExcelDialog(true);
    }
}
const handleCloseExcelDialog = () =>{
    setOpenExcelDialog(false);
    dispatch(emptyListFromExcel());
}
const onSearchDistributor = (e)=>{
    if(dataComboDistributor.length<1){
        dispatch(getAllDistributorForCombo(e.target.value,token,setOpensnackbar));
    }
  }
const handleChangeJatuhtempo = (e)=>{
    formik.setFieldValue('jatuhtempo',e.target.value);
    formik.setFieldValue('tanggalJatuhTempo',new Date());
}
const ppnEnable = (
    <Fragment>
        <Typography sx={{marginRight:'5px'}}>
            PPN
        </Typography>
        <TextField
        id="configppn"
        name="configppn"
        sx={{width:'30px'}}
        variant='standard'
        disabled={disabledPPN}
        value={labelSwitch}
        onChange={(e)=>handleChangeValuePPN(e)}
        onKeyDown={onEnterEditPPN}/>
        <Typography sx={{marginLeft:'5px'}}>
            %
        </Typography>
        <IconButton 
        size='small'
        onClick={handleClickEditPPN}
        >
            <img src={EditStok} alt="addTodo"/>
        </IconButton>
    </Fragment>
)
const daftarPembelian = (
    <TableBody>
            {cartPembelian.slice(0).reverse().map((pembelian) => (
                <StyledTableRow key={pembelian.id}>
                <StyledTableCell width={120} align="center">{pembelian.idbarang}</StyledTableCell>
                <StyledTableCell width={200} align="center">{pembelian.namabarang}</StyledTableCell>
                <StyledTableCell width={120} align="center">{pembelian.satuan}</StyledTableCell>
                <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(pembelian.qty)}</StyledTableCell>
                <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(pembelian.harga)}</StyledTableCell>
                <StyledTableCell width={120} align="center">
                {pembelian.diskon.map((dis)=>(
                   dis.jenisdiskon === '%' ? " "+dis.diskon + dis.jenisdiskon +" ": " "+new Intl.NumberFormat().format(dis.diskon) +" "
                ))}</StyledTableCell>
                <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(pembelian.harganet)}</StyledTableCell>
                <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(pembelian.jumlah)}</StyledTableCell>
                <StyledTableCell width={20} align="center">
                    <Tooltip title="Hapus data">
                        <IconButton 
                        disabled={timeConnection<=100 ? false:true}
                        onClick={()=>handleClickDeletePembelian(pembelian.id)}
                        >
                            <img src={DeleteData} alt="deleteData"/>
                        </IconButton>
                    </Tooltip>
                </StyledTableCell>
                </StyledTableRow>
            ))}
        </TableBody>
)
  return (
    <Container maxWidth='xl' sx={{marginTop:'100px'}}>
        <Typography variant='h6' pl={1} mt={1}>Pembelian</Typography>
        <Divider/>
        <Box sx={boxWapper}>
            <Box sx={paperInput}>
                <Box sx={{width:'35%',display:'flex',justifyContent:'space-between',marginRight:'10px'}}>
                    <Checkbox
                    size="small"
                    sx={{marginTop:'10px'}}
                    id='autoid'
                    name='autoid'
                    checked={autoId}
                    onClick={(e)=>handleChangeChackeAutoId(e)}
                    value={autoId}
                    />
                    <TextField
                    id="fakturpembelian"
                    name="fakturpembelian"
                    label="Nomor Faktur"
                    variant="standard"
                    disabled={disableFaktur}
                    style={{width:'100%',marginRight:'10px'}}
                    value={formik.values.fakturpembelian}
                    error={formik.touched.fakturpembelian && Boolean(formik.errors.fakturpembelian)}
                    helperText={formik.touched.fakturpembelian && formik.errors.fakturpembelian}
                    onChange={formik.handleChange}
                    />
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                    label="Tanggal"
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => 
                    <TextField {...params} sx={{width:'30%',marginRight:'10px'}} variant="standard"
                    error={formik.touched.tanggal && Boolean(formik.errors.tanggal)}
                    helperText={formik.touched.tanggal && formik.errors.tanggal}
                    />}
                    value={formik.values.tanggal}
                    onChange={(e)=>handleChangeTanggal(e)}
                    />
                 </LocalizationProvider>
                 <Autocomplete
                  id="iddistributor"
                  selectOnFocus
                  name='iddistributor'
                  label="Distributor"
                  variant="standard"
                  sx={{width:'350px',marginRight:'10px'}}
                  options= {dataComboDistributor}
                  getOptionLabel= {(option) => option.nama}
                  isOptionEqualToValue={(option,value)=>option.id===value.id}
                  onChange={(event, newValue) => {
                          if(newValue!==null){
                              formik.setFieldValue('iddistributor',newValue.iddistributor);
                              setValueID(newValue.iddistributor);
                              setNamaDist(newValue.nama);
                          }else{
                              formik.setFieldValue('iddistributor','');
                              setValueID("");
                              setNamaDist("");
                          }
                    }}
                  renderInput={(params)=><TextField
                      id='iddistributor'
                      name='iddistributor'
                      variant='standard' 
                      {...params} 
                      label="Distributor"
                      value={formik.values.iddistributor}
                      error={formik.touched.iddistributor && Boolean(formik.errors.iddistributor)}
                      helperText={formik.touched.iddistributor && formik.errors.iddistributor}
                      onChange={(e)=>onSearchDistributor(e)}
                      />}
                  />
                    {/* <TextField
                    id='iddistributor'
                    select
                    name='iddistributor'
                    variant="standard" 
                    label="Distributor"
                    style={{width:'30%',marginRight:'10px'}}
                    SelectProps={{
                        native: true,
                        }}
                    value={formik.values.iddistributor}
                    error={formik.touched.iddistributor && Boolean(formik.errors.iddistributor)}
                    helperText={formik.touched.iddistributor && formik.errors.iddistributor}
                    onChange={(e)=>handleChangeDistributor(e)}
                    >   
                        <option aria-label="None" value="" />
                        {dataComboDistributor.map((option)=>(
                            <option key ={option.iddistributor} value={option.iddistributor}>
                                {option.nama}
                            </option>
                        ))}
                    </TextField> */}
                <TextField
                id='pembayaran'
                select
                name='pembayaran'
                variant="standard" 
                label="Pembayaran"
                style={{width:'30%',marginRight:'10px'}}
                SelectProps={{
                    native: true,
                    }}
                value={formik.values.pembayaran}
                error={formik.touched.pembayaran && Boolean(formik.errors.pembayaran)}
                helperText={formik.touched.pembayaran && formik.errors.pembayaran}
                onChange={(e)=>onSelectPembayaranHandleChange(e)}
                >   
                    <option aria-label="None" value="" />
                    {valuePembayaran.map((pembayaran)=>(
                        <option key ={pembayaran.value} value={pembayaran.value}>
                            {pembayaran.label}
                        </option>
                    ))}
                </TextField>
                <TextField
                id='jatuhtempo'
                name='jatuhtempo'
                variant="standard" 
                label="Tempo(Hari)"
                disabled={tempo}
                onKeyDown={handleEnter}
                style={{width:'20%',marginRight:'10px'}}
                value={formik.values.jatuhtempo}
                error={formik.touched.jatuhtempo && Boolean(formik.errors.jatuhtempo)}
                helperText={formik.touched.jatuhtempo && formik.errors.jatuhtempo}
                onChange={(e)=>handleChangeJatuhtempo(e)}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                    label="Jatuh Tempo"
                    inputFormat="dd/MM/yyyy"
                    disabled={true}
                    value={formik.values.tanggalJatuhTempo}
                    onChange={formik.handleChange}
                    renderInput={(params) => <TextField {...params} sx={{width:'30%',marginRight:'10px'}} variant="standard"
                    error={formik.touched.tanggalJatuhTempo && Boolean(formik.errors.tanggalJatuhTempo)}
                    helperText={formik.touched.tanggalJatuhTempo && formik.errors.tanggalJatuhTempo}
                    />}
                    />
                 </LocalizationProvider>
            </Box>
        </Box>
        <Box sx={pembelianInput}>
                <Divider/>
                <Box sx={fieldInput}>
                <Button variant='contained' 
                color="primary" 
                sx={{marginRight:'10px'}} 
                size="small" 
                disabled={timeConnection<=100 ? false:true}
                onClick={()=>handleOpenDialog()}>Tambah Daftar</Button>
                <Button variant='contained' 
                color="error" 
                sx={{marginRight:'10px'}} 
                size="small" 
                disabled={timeConnection<=100 ? false:true}
                onClick={()=>handleOpenBarangbaru()}>Barang Baru</Button>
                <Button variant='contained' 
                color="success" 
                size="small" 
                disabled={timeConnection<=100 ? false:true}
                onClick={()=>handleOpenExcelDialog()}>Data Excel</Button>
                </Box>
                <TableContainer component={Paper} sx={paperTable}>
                <Table stickyHeader aria-label="customized table" size='small'>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell width={120} align="center">ID Barang</StyledTableCell>
                            <StyledTableCell width={200} align="center">Nama Barang</StyledTableCell>
                            <StyledTableCell width={120} align="center">Satuan</StyledTableCell>
                            <StyledTableCell width={120} align="center">Qty</StyledTableCell>
                            <StyledTableCell width={120} align="center">Harga</StyledTableCell>
                            <StyledTableCell width={120} align="center">Diskon</StyledTableCell>
                            <StyledTableCell width={120} align="center">Harga Netto</StyledTableCell>
                            <StyledTableCell width={120} align="center">Jumlah</StyledTableCell>
                            <StyledTableCell width={20} align="center"/>
                        </TableRow>
                    </TableHead>
                        {daftarPembelian} 
                </Table>
            </TableContainer>
            <Box sx={{width:'90%',display:'flex',flexDirection:'column'}}>
                <Box sx={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                    <Box sx={{width:'200px',textAlign:'right'}}>
                        <Typography>Subtotal:</Typography>
                    </Box>
                    <Box sx={{width:'180px',textAlign:'right'}}>
                        <Typography>{new Intl.NumberFormat().format(subtotal)}</Typography>
                    </Box>
                </Box>
                <Box sx={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                    <Box sx={{width:'200px',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                        <Typography>Diskon {listDiskonTotal.map((dis)=>(
                            dis.jenisdiskon === '%' ? " "+dis.diskon + dis.jenisdiskon +" ": " "+new Intl.NumberFormat().format(dis.diskon) +" "
                        ))} </Typography>
                        <IconButton 
                        size='small'
                        disabled={buttonDiskon}
                        onClick={listDiskonTotal.length<1 ? handleOpenDiskonDialog : handleClearDiskon}
                        >
                            <img src={listDiskonTotal.length<1 ? AddTodo : DeleteData} alt="addTodo"/>
                        </IconButton>
                    </Box>
                    <Box sx={{width:'180px',textAlign:'right'}}>
                        <Typography marginTop={0.5}>{new Intl.NumberFormat().format(diskonTotal)}</Typography>
                    </Box>
                </Box>
                <Box sx={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                    <Box sx={{width:'200px',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                        <Checkbox
                        size="small"
                        id='checkppn'
                        name='checkppn'
                        checked={checkPPN}
                        onClick={(e)=>handleCheckPPN(e)}
                        value={checkPPN}
                        />
                        <Typography>PPN</Typography>
                    </Box>
                    <Box sx={{width:'180px',textAlign:'right'}}>
                        
                    </Box>
                </Box>
                <Box sx={{width:'100%',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                    <Box sx={{width:'300px',textAlign:'right',display:'flex',justifyContent:'flex-end'}}>
                        <Typography>Barang Termasuk PPN</Typography>
                        <Switch size='small' checked={checkSwitch} onChange={(e)=>handleChangeSwitch(e)}/>
                    </Box>
                    <Box sx={{width:'180px',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                        {labelSwitch !== 'Tanpa PPN' ? 
                        ppnEnable
                        : <Typography>Tanpa PPN</Typography>}
                    </Box>
                </Box>
                <Box sx={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                    <Box sx={{width:'200px',textAlign:'right'}}>
                        <Typography>Total DPP:</Typography>
                    </Box>
                    <Box sx={{width:'180px',textAlign:'right'}}>
                        <Typography>Rp {new Intl.NumberFormat().format(totalDPP)}</Typography>
                    </Box>
                </Box>
                <Box sx={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                    <Box sx={{width:'200px',textAlign:'right'}}>
                        <Typography>Total PPN:</Typography>
                    </Box>
                    <Box sx={{width:'180px',textAlign:'right'}}>
                        <Typography>Rp {new Intl.NumberFormat().format(totalPPN)}</Typography>
                    </Box>
                </Box>
                <Box sx={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                    <Box sx={{width:'200px',textAlign:'right'}}>
                        <Typography>Total Pembelian:</Typography>
                    </Box>
                    <Box sx={{width:'180px',textAlign:'right'}}>
                        <Typography>Rp {new Intl.NumberFormat().format(grandTotal)}</Typography>
                    </Box>
                </Box>
                <Box sx={{width:'100%',display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                        <Checkbox
                        size="small"
                        id='cekretur'
                        name='cekretur'
                        disabled={timeConnection<=100 ? false:true}
                        checked={checkRetur}
                        onClick={(e)=>handleCheckRetur(e)}
                        value={checkRetur}
                        />
                        <Typography sx={{marginRight:'10px'}}>Retur Pembelian</Typography>
                        <TextField
                        id='asalretur'
                        select
                        name='asalretur'
                        variant="standard" 
                        value={asalReturText}
                        sx={{width:'120px'}}
                        SelectProps={{
                            native: true,
                            }}
                        disabled={disabledAsalRetur}
                        onChange={(e)=>handleChangeAsalRetur(e)}
                        >   
                            <option aria-label="None" value=""/>
                            {asalRetur.map((option)=>(
                                <option key ={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </TextField>
                </Box>
                {checkRetur === true && asalReturText!=="" ? <ReturPembelian iddist={formik.values.iddistributor} asalRetur={asalReturText} totalPembelian={grandTotal}/> : null}
               
            </Box>
            <Button variant='contained' 
            color='primary' 
            sx={{marginBottom:"10px"}} 
            disabled={timeConnection<=100 ? isLoading:true}
            onClick={formik.handleSubmit}>Simpan</Button>
            </Box>
            <BarangListDialog openDialog={openDialog} iddist={valueID} namadist={namaDist} closeDialog={handleCloseDialog} status={statusDialog}/>
            <BarangBaruDialog openBarangBaru={openBarangBaru} iddist={valueID} namadist={namaDist} closeBarangBaru={handleCloseBarangbaru}/>
            <DiskonBarangDialog openDiskonDialog={openDiskonDialog} closeDiskonDialog={handleCloseDiskonDialog} totalPembelian={subtotal}/>
            <ImportExcelDialog openExcelDialog={openExcelDialog} handleCloseExcelDialog={handleCloseExcelDialog}/>
            <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={pembelianMessage === "Transaksi pembelian berhasil dilakukan" ? "success" : "error"}
                >
                    {pembelianMessage}
                    </Alert>
            </Snackbar>
    </Container>
  )
};

export default PembelianPage;
