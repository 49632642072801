import React, { useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import { Box, Container, Fab, Snackbar, Typography, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { getPenjualanDetailsPPN, setEmptyDataPenjualan, setPenjualanMessage } from '../../../config/redux/actions';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
const LaporanPenjualDetails = () => {
  const theme = useTheme();
  const {fakturpenjualan,ppn}=useParams();
  const {penjualanMessage,penjualanDetails,penjualanSingle}=useSelector(state=>state.penjualanReducers);
  const {dataLogin} =useSelector(state=>state.penggunaReducers);
  const [token,setToken] = useState(dataLogin.token);
  const [openSnackbar,setOpensnackbar]=useState(false);
  const [totalPenjualan,setTotalPenjualan] = useState(0);
  const dispatch = useDispatch();
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const history=useHistory();
    useEffect(()=>{
        const data={
            'fakturpenjualan':fakturpenjualan,
            'tempPPN':ppn
        }
        dispatch(getPenjualanDetailsPPN(data,token,setOpensnackbar));
        if(penjualanDetails.length>0){
            let tempTotalPenjualan=penjualanDetails.reduce((Penjualan,TotalP)=>{
                return Penjualan + TotalP.jumlah
            },0);
            setTotalPenjualan(tempTotalPenjualan);
          }else{
              setTotalPenjualan(0);
          }
    },[dispatch,token,fakturpenjualan,penjualanDetails,ppn])
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(setPenjualanMessage(''));
        }, 100);
      }
    const handleClickBack = () =>{
        dispatch(setEmptyDataPenjualan());
        history.push('/laporan/penjualan');
    }
  return (
    <Container maxWidth='xl' sx={{marginTop:'100px',display:'flex',justifyContent:'center'}}>
        <Box sx={{width:'21.59cm',display:'flex',flexDirection:'column'}}>
        <Typography variant='body1' textAlign="center"><b>NOTA PENJUALAN</b></Typography>
            <Box sx={{width:'100%',borderBottom:'1px dotted black'}}/>
            <Box sx={{width:'100%',display:"flex",justifyContent:'space-between',marginTop:'10px'}}>
                <Typography variant='caption'>Tanggal : {penjualanSingle.tanggal !== undefined ? format(new Date(penjualanSingle.tanggal),'dd MMM yyyy') : ''}</Typography>
                <Typography variant='caption'>No Faktur : {penjualanSingle.fakturpenjualan}</Typography>
                <Typography variant='caption'>Pelanggan :{penjualanSingle.alias === "" ? penjualanSingle.nama : penjualanSingle.nama+"("+penjualanSingle.alias+")"}</Typography>
            </Box>
            <Box sx={{width:'100%',borderBottom:'1px dotted black',marginTop:'10px'}}/>
                <table aria-label="table barang" size="small" style={{width:'100%',marginTop:'5px',borderBottom:'1px dotted black'}}>
                    <thead >
                        <tr>
                        <th style={{width:'3%',textAlign:"center"}}><Typography variant='body2'>No</Typography></th>
                        <th style={{width:'30%',textAlign:"center"}}><Typography variant='body2'>Nama Barang</Typography></th>
                        <th style={{width:'13%',textAlign:"center"}}><Typography variant='body2'>Harga</Typography></th>
                        <th style={{width:'13%',textAlign:"center"}}><Typography variant='body2'>Diskon</Typography></th>
                        <th style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Qty</Typography></th>
                        <th style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Satuan</Typography></th>
                        <th style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Jumlah</Typography></th>
                        </tr>
                    </thead>
                    <tbody>
                    {penjualanDetails.map(((details,index)=>(
                        <tr key={details.idpenjualandetails}>
                            <td align="center"><Typography variant='body2'>{index+1}</Typography></td>
                            <td align="left"><Typography variant='body2'><b>{details.namabarang}</b></Typography></td>
                            <td align="center"><Typography variant='body2'><b>{new Intl.NumberFormat().format(details.harga) || '0'}</b></Typography></td>
                            <td align="center"><Typography variant='body2'><b>{new Intl.NumberFormat().format(details.diskon) || '0'}</b></Typography></td>
                            <td align="center"><Typography variant='body2'><b>{details.qty  || '0'}</b></Typography></td>
                            <td align="center"><Typography variant='body2'><b>{details.satuan}</b></Typography></td>
                            <td align="center"><Typography variant='body2'><b>{new Intl.NumberFormat().format(details.jumlah) || '0'}</b></Typography></td>
                        </tr>
                    )))}
                    </tbody>
                </table>
                <Box sx={{width:'100%',display:'flex',alignItems:'flex-end',justifyContent:'flex-end'}}>
                    <Box sx={{display:'flex',flexDirection:'column',marginRight:'2px'}}>
                        <Typography variant="body2">
                            Total Item
                        </Typography>
                        <Typography variant="body2">
                            Total
                        </Typography>
                    </Box>
                    <Box sx={{display:'flex',flexDirection:'column',marginRight:'2px'}}>
                        <Typography variant="body2">
                            :
                        </Typography>
                        <Typography variant="body2">
                            :
                        </Typography>
                    </Box>
                    <Box sx={{display:'flex',flexDirection:'column',marginRight:'2px'}}>
                        <Typography variant="body2" textAlign="center">
                            <b>{new Intl.NumberFormat().format(penjualanSingle.totalitem) || '0'}</b>
                        </Typography>
                        <Typography variant="body2">
                        <b>{new Intl.NumberFormat().format(totalPenjualan) || '0'}</b>
                        </Typography>
                    </Box>
                </Box>
        </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={1000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={penjualanMessage === "Berhasil menampilkann data penjualan" ? "success" : "error"}
                >
                    {penjualanMessage}
                    </Alert>
            </Snackbar>
            <Fab sx={{position: 'fixed',bottom: theme.spacing(5),left: theme.spacing(3)}} size="small" color="primary" aria-label="add" onClick={()=>handleClickBack()}>
                <ArrowBackIcon/>
            </Fab>
    </Container>
  )
}

export default LaporanPenjualDetails