import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Backdrop, Box,Container, Divider, Fab, IconButton, LinearProgress, Paper, Radio, Snackbar, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography,useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import { getDataPenjualan, setEmptyPenjualanToPrint, setPenjualanMessage,pembatalanPenjualan, getAllPenjualanDetails, setEmptyDataPenjualan } from '../../../config/redux/actions';
import { DeleteData,DisabledDeleteData, DetailsIcon, PrintIcon } from '../../../components/assets';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { DeleteDialog, ExportToXLSX } from '../../../components/molecules';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'grey',
      color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const LaporanPenjualanPage = () => {
  const jenisLaporan = [
    {
      value: 'ALL',
      label: 'Semua',
    },
    {
      value: 'PPN',
      label: 'PPN',
    },
    {
      value: 'NON PPN',
      label: 'NON PPN',
    }
  ];
  const theme = useTheme();
  const boxWapper = {
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    [theme.breakpoints.up('sm')]:{
        width:'100%',
        maxHeight:'500px',
        height:'100%',
    },
    [theme.breakpoints.down('lg')]:{
        width:'100%',
        height:'100%',
    }
  }
  const paperInput={
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      [theme.breakpoints.up('sm')]:{
          width:"90%",
          height:'100%',
          borderRadius:'10px',
          marginTop:'10px'
      },
      [theme.breakpoints.down('sm')]:{
          width:'100%',
          height:'100%',
          borderRadius:'10px',
          marginTop:'10px'
      }
  }
  const paperTable = {
    marginTop:'10px',
    [theme.breakpoints.up('sm')]:{
        width:'90%',
        height:'400px',
    }, 
    [theme.breakpoints.down('sm')]:{
        width:'100%',
        height:'360px'
    },
    scrollbarWidth:'thin'
}
  const {dataPenjualan,penjualanMessage,isLoading,dataAllPenjualanDetails}=useSelector(state=>state.penjualanReducers);
  const {dataLogin} =useSelector(state=>state.penggunaReducers);
  const [token,setToken] = useState(dataLogin.token);
  const [openSnackbar,setOpensnackbar]=useState(false);
  const [tanggalawal,setTanggalawal]=useState(new Date());
  const [tanggalakhir,setTanggalakhir]=useState(new Date());
  const [laporan,setLaporan]=useState('ALL');
  const [totalPenjualan,setTotalPenjualan]=useState(0);
  const [openDialog,setOpenDialog]=useState(false);
  const [messageDialog,setMessageDialog]=useState('');
  const [tempNoFaktur,setNoFaktur] = useState('');
  const [disabledButton,setDisabledButton]=useState(true);
  const [findBy,setFindBy]=useState('tanggal');
  const [disabledTanggal,setDisabledTanggal]=useState(false);
  const [disabledFaktur,setDisabledFaktur]=useState(true);
  const [faktur,setFaktur]=useState('');
  const dispatch = useDispatch();
  if(token ===''){
    setToken(Cookies.get('u_tkn'));
  }
  useEffect(()=>{
    if(findBy === "tanggal"){
      if(dataPenjualan.length<1 && format(tanggalawal,"yyyy-MM-dd") === format(new Date(),"yyyy-MM-dd")){
        const data={
            'findBy':findBy,
            'faktur':"",
            'tanggalawal':format(new Date(),"yyyy-MM-dd"),
            'tanggalakhir':format(new Date(),"yyyy-MM-dd"),
            'statusPPN':'ALL'
          }
          dispatch(getDataPenjualan(data,token,setOpensnackbar))
          dispatch(getAllPenjualanDetails(data,token,setOpensnackbar))
        }
        if(tanggalawal>tanggalakhir){
          dispatch(setPenjualanMessage('Tanggal tidak sesuai'));
          setOpensnackbar(true);
          setTanggalawal(new Date());
          setTanggalakhir(new Date());
        }
    }
        if(dataPenjualan.length>0){
          let totalPenjualan=dataPenjualan.reduce((Penjualan,TotalP)=>{
              if(TotalP.status==="Berhasil"){
                Penjualan += TotalP.total
              }
              return Penjualan
          },0);
          setTotalPenjualan(totalPenjualan);
          setDisabledButton(false);
        }else{
            setTotalPenjualan(0);
            setDisabledButton(true);
        }
          dispatch(setEmptyPenjualanToPrint());
  },[dataPenjualan,totalPenjualan,token])
  const handleCloseSnackbar = (event,reason) =>{
    if(reason === 'clickaway'){
        return;
    }
    setOpensnackbar(false);
    setTimeout(() => {
        dispatch(setPenjualanMessage(''));
    }, 100);
  }
  const handleChangeTanggalAwal = (e)=>{
    if(e>tanggalakhir){
      dispatch(setPenjualanMessage('Tanggal tidak sesuai'));
      setOpensnackbar(true);
      setTanggalawal(new Date());
      setTanggalakhir(new Date());
    }
    else{
      setTanggalawal(e);
    }
  }
  const handleChangeTanggalAkhir = (e)=>{
    if(e<tanggalawal){
      dispatch(setPenjualanMessage('Tanggal tidak sesuai'));
      setOpensnackbar(true);
      setTanggalawal(new Date());
      setTanggalakhir(new Date());
    }else{
      setTanggalakhir(e);
    }
  }
  const history = useHistory();
  const handlePrintFaktur = (faktur) =>{
    history.push(`/laporan/penjualan/${faktur}`);
  }
  const handleChangeLaporan = (e) =>{
    setLaporan(e.target.value);
  }
  const handleClickButton = ()=>{
    if(findBy==="tanggal"){
      console.log(findBy);
      const data={
        'findBy':findBy,
        'faktur':"",
        'tanggalawal':format(tanggalawal,"yyyy-MM-dd"),
        'tanggalakhir':format(tanggalakhir,"yyyy-MM-dd"),
        'statusPPN':laporan
      }
      dispatch(getDataPenjualan(data,token,setOpensnackbar))
      dispatch(getAllPenjualanDetails(data,token,setOpensnackbar))
    }else if(findBy==="faktur"){
      const data={
        'findBy':findBy,
        'faktur':faktur,
        'tanggalawal':null,
        'tanggalakhir':null,
        'statusPPN':laporan
      }
      dispatch(getDataPenjualan(data,token,setOpensnackbar))
      dispatch(getAllPenjualanDetails(data,token,setOpensnackbar))
    }
  }
  const handleClickDetails = (faktur) =>{
     history.push(`/laporan/penjualan/${laporan}/${faktur}`);
  }
  const handleClickPembatalanPenjualan = (faktur) =>{
    setMessageDialog(`Apakah anda yakin akan membatalkan penjualan ${faktur}?`);
    setNoFaktur(faktur);
    setOpenDialog(true);
  }
  const handlePembatalanPenjualan = () =>{
    const data = {
      'fakturpenjualan':tempNoFaktur,
      'tanggal':format(new Date(),"yyyy-MM-dd"),
      'tanggalawal':format(tanggalawal,"yyyy-MM-dd"),
      'tanggalakhir':format(tanggalakhir,"yyyy-MM-dd"),
      'statusPPN':'ALL'
    }
      dispatch(pembatalanPenjualan(data,token,setOpensnackbar,setOpenDialog));
      dispatch(getAllPenjualanDetails(data,token,setOpensnackbar))
  }
  const handleCloseDialog = ()=>{
    setOpenDialog(false);
  }
  const handleClickBack = ()=>{
    dispatch(setEmptyDataPenjualan());
    history.push('/laporan/');
  }
  const tableDataPenjualan = (
    <TableBody>
        {dataPenjualan.map((penjualan) => (
            <StyledTableRow key={penjualan.fakturpenjualan}>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{format(new Date(penjualan.tanggal),'dd/MM/yyyy')}</Typography></StyledTableCell>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{penjualan.fakturpenjualan}</Typography></StyledTableCell>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{penjualan.alias === "" ? penjualan.nama : penjualan.nama+"("+penjualan.alias+")"}</Typography></StyledTableCell>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(penjualan.totalitem)}</Typography></StyledTableCell>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(penjualan.total)}</Typography></StyledTableCell>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{penjualan.status}</Typography></StyledTableCell>
            <StyledTableCell width={120} align="center">
              <Tooltip title="Cetak Faktur">
                <IconButton 
                onClick={()=>handlePrintFaktur(penjualan.fakturpenjualan)}
                >
                  <img src={PrintIcon} alt="print"/>
                </IconButton>
              </Tooltip>
              <Tooltip title="Rincian Penjualan">
                <IconButton 
                onClick={()=>handleClickDetails(penjualan.fakturpenjualan)}
                >
                  <img src={DetailsIcon} alt="print"/>
                </IconButton>
              </Tooltip>
              {penjualan.status==="Berhasil"?
              <Tooltip title="Batalkan Penjualan">
              <IconButton
              onClick={()=>handleClickPembatalanPenjualan(penjualan.fakturpenjualan)}
              >
                <img src={DeleteData} alt="details"/>
              </IconButton>
            </Tooltip> : 
            <IconButton 
            disabled={true}
            onClick={()=>handleClickPembatalanPenjualan(penjualan.fakturpenjualan)}
            >
              <img src={DisabledDeleteData} alt="details"/>
            </IconButton>
            }
              </StyledTableCell>
            </StyledTableRow>
        ))}
    </TableBody>
)
const handleChangeRadio = (e) =>{
  setFindBy(e.target.value);
  if(e.target.value==="tanggal"){
    dispatch(setEmptyDataPenjualan());
    setLaporan("ALL");
    setFaktur("");
    setDisabledTanggal(false);
    setDisabledFaktur(true);
  }else if(e.target.value==="faktur"){
    dispatch(setEmptyDataPenjualan());
    setLaporan("ALL");
    setTanggalawal(new Date());
    setTanggalakhir(new Date());
    setDisabledTanggal(true);
    setDisabledFaktur(false);
  }
}
const handleChangeFaktur = (e) =>{
  setFaktur(e.target.value);
}
let fileName = "Penjualan details periode "+format(tanggalawal,"ddMMyyyy")+"-"+format(tanggalakhir,"ddMMyyyy");
  return (
    <Container maxWidth='xl' sx={{marginTop:'100px'}}>
        <Typography variant='h6' pl={1} mt={1}>Laporan Penjualan</Typography>
        <Divider/>
        <Box sx={boxWapper}>
          <Box sx={paperInput}>
            <Radio
              style={{marginTop:'15px'}}
              checked={findBy === 'tanggal'}
              onChange={handleChangeRadio}
              value="tanggal"
              name="tanggal-radio"
            />
            <TextField
            id='laporan'
            select
            name='laporan'
            variant="standard" 
            label="Laporan"
            disabled={disabledTanggal}
            style={{marginRight:'10px'}}
            SelectProps={{
                native: true,
                }}
            value={laporan}
            onChange={(e)=>handleChangeLaporan(e)}
            >   
                {jenisLaporan.map((option)=>(
                    <option key ={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </TextField>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
            label="Tanggal Awal"
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => <TextField {...params} sx={{width:'150px',marginRight:'20px'}} variant="standard"/>}
            value={tanggalawal}
            disabled={disabledTanggal}
            onChange={(e)=>handleChangeTanggalAwal(e)}
            />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
            label="Tanggal Akhir"
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => <TextField {...params} sx={{width:'150px',marginRight:'10px'}} variant="standard"/>}
            value={tanggalakhir}
            disabled={disabledTanggal}
            onChange={(e)=>handleChangeTanggalAkhir(e)}
            />
            </LocalizationProvider>
            <Radio
              style={{marginTop:'15px'}}
              checked={findBy === 'faktur'}
              onChange={handleChangeRadio}
              value="faktur"
              name="faktur-radio"
            />
            <TextField
            variant='standard'
            id="fakturSearch"
            label="Pencarian Faktur"
            disabled={disabledFaktur}
            value={faktur}
            onChange={handleChangeFaktur}
            />
            <Tooltip title="Tampilkan Data">
                <IconButton onClick={()=>handleClickButton()}>
                    <ContentPasteSearchIcon/>
                </IconButton>
            </Tooltip>
            <ExportToXLSX csvData={dataAllPenjualanDetails} fileName={fileName} disabled={disabledButton}/>
          </Box>
          <TableContainer component={Paper} sx={paperTable}>
                  <Table stickyHeader aria-label="customized table" size='small'>
                      <TableHead>
                      <TableRow>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Tanggal</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>No Faktur</Typography></StyledTableCell>
                          <StyledTableCell width={200} align="center"><Typography sx={{fontSize:12}}>Pelanggan</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Total Item</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Total</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Status</Typography></StyledTableCell>
                          <StyledTableCell width={20} align="center"></StyledTableCell>
                      </TableRow>
                      </TableHead>
                          {tableDataPenjualan} 
                  </Table>
               </TableContainer>
               {/* <Box sx={{width:'90%',display:'flex',justifyContent:'flex-end'}}>
                  <Typography>Total Penjualan : {new Intl.NumberFormat().format(totalPenjualan)}</Typography>
               </Box> */}
        </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={1000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={penjualanMessage === "Pembatalan penjualan berhasil" ? "success" : "error"}
                >
                    {penjualanMessage}
                    </Alert>
            </Snackbar>
            <DeleteDialog 
            openDialog={openDialog} 
            message={messageDialog}
            handleCloseDialog={handleCloseDialog}
            handleClick={handlePembatalanPenjualan}
            isLoading={isLoading}
            textHapus={"Ya"}
            textKeluar={"Tidak"}/>
            <Fab sx={{position: 'fixed',bottom: theme.spacing(5),left: theme.spacing(3)}} size="small" color="primary" aria-label="add" onClick={()=>handleClickBack()}>
                <ArrowBackIcon/>
            </Fab>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            >
            <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
            <Typography>Loading</Typography>
            <LinearProgress color="inherit"/>
            </Box>
            </Backdrop>
    </Container>
  )
}

export default LaporanPenjualanPage