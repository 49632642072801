import React, { useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import {Container,Typography,styled, useTheme,Divider,Box, TextField, TableContainer, Table, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, Tooltip, IconButton, Snackbar, Backdrop, CircularProgress, FormControl, RadioGroup, Radio, FormControlLabel, Fab} from '@mui/material';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { getListTahun, getRincianStokBarang, getRincianStokBarangByAll, getRincianStokBarangByTgl, setStokBarangMessage } from '../../../config/redux/actions';
import { TablePagination } from '../../../components/molecules';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format } from 'date-fns';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { useHistory } from 'react-router-dom';
import { EditStokAkhir, KoreksiStok } from '../../../components/assets';
import EditStokAkhirDialog from '../../../components/molecules/EditStokAkhirDialog';
import KoreksiStokDialog from '../../../components/molecules/KoreksiStokDialog';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'grey',
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const RincianStokBarang = () => {
    const theme = useTheme();
    const paperTable = {
        marginTop:'10px',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            height:'400px',
        }, 
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'360px'
        },
        scrollbarWidth:'thin'
    }
    const boxSearch = {
        [theme.breakpoints.up('sm')]:{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'flex-end',
            width:'100%',
        }, 
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'flex-end',
            width:'100%',
        }
    }
    const bulanCombo = [
        {'label':'Januari','value':'1'},
        {'label':'Februari','value':'2'},
        {'label':'Maret','value':'3'},
        {'label':'April','value':'4'},
        {'label':'Mei','value':'5'},
        {'label':'Juni','value':'6'},
        {'label':'Juli','value':'7'},
        {'label':'Agustus','value':'8'},
        {'label':'September','value':'9'},
        {'label':'Oktober','value':'10'},
        {'label':'November','value':'11'},
        {'label':'Desember','value':'12'}
    ]
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const {stokbarangMessage,dataRincianStokBarang,pageRincianStokBarang,isLoading,listTahun,dataRincian} =useSelector(state=>state.stokbarangReducers);
    const {idsatuanbarang,namabarang}=dataRincian;
    const dispatch = useDispatch();
    const [token,setToken] = useState(dataLogin.token);
    const [counter,setCounter] = useState(1);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [valueRadio, setValueRadio] = useState('All');
    const [disableTanggal,setDisableTanggal] = useState(true);
    const [disablePeriode,setDisablePeriode] = useState(true);
    const [openEditStokDialog,setOpenEditStokDialog] = useState(false);
    const [openKoreksiStok,setOpenKoreksiStok] = useState(false);
    const history = useHistory();
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        if(disableTanggal===true && disablePeriode===true){
            const data={
                'idsatuanbarang':idsatuanbarang
            }
            dispatch(getRincianStokBarangByAll(data,counter,token,setOpensnackbar));
        }
        else if(disableTanggal === false && disablePeriode === true){
            const data={
                'idsatuanbarang':idsatuanbarang,
                'tglawal':format(formik.values.tglawal,"yyyy-MM-dd"),
                'tglakhir':format(formik.values.tglakhir,"yyyy-MM-dd"),
            }
            dispatch(getRincianStokBarangByTgl(data,counter,token,setOpensnackbar));
        }else if(disableTanggal === true && disablePeriode === false){
            const data={
                'idsatuanbarang':idsatuanbarang,
                'bulan':formik.values.bulan,
                'tahun':formik.values.tahun,
            }
            dispatch(getRincianStokBarang(data,counter,token,setOpensnackbar));
        }
        if(listTahun.length<1){
            dispatch(getListTahun(token,setOpensnackbar));
        }
    },[listTahun,disableTanggal,idsatuanbarang,counter,token,dispatch])
    
    const handleChange = (event) => {
        setValueRadio(event.target.value);
        if(event.target.value==="All"){
            setDisableTanggal(true);
            setDisablePeriode(true);
            formik.setFieldValue('bulan',"");
            formik.setFieldValue('tahun',new Date());
            formik.setFieldValue('tglawal',new Date());
            formik.setFieldValue('tglakhir',new Date());
        }else if(event.target.value === "Tanggal"){
            setDisableTanggal(false);
            setDisablePeriode(true);
            formik.setFieldValue('bulan',"");
            formik.setFieldValue('tahun',new Date());
        }else if(event.target.value === "Periode"){
            setDisableTanggal(true);
            setDisablePeriode(false);
            formik.setFieldValue('tglawal',new Date());
            formik.setFieldValue('tglakhir',new Date());
        }
    };
    const handleChangeTanggalAwal = (e)=>{
        formik.setFieldValue('tglawal',e);
    }
    const handleChangeTanggalAkhir = (e)=>{
        formik.setFieldValue('tglakhir',e);
    }
    const tableStokBarang = (
        <TableBody>
            {dataRincianStokBarang.map((stokbarangs) => (
                <StyledTableRow key={stokbarangs.idstokbarang}>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:10}}>{format(new Date(stokbarangs.tanggal),'dd/MM/yyyy')}</Typography></StyledTableCell>
                <StyledTableCell width={200} align="center"><Typography sx={{fontSize:10}}>{stokbarangs.namabarang}</Typography></StyledTableCell>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{stokbarangs.kelompok}</Typography></StyledTableCell>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{stokbarangs.satuan}</Typography></StyledTableCell>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{stokbarangs.stokm}</Typography></StyledTableCell>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(stokbarangs.hargam)}</Typography></StyledTableCell>
                <StyledTableCell width={200} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(stokbarangs.totalm)}</Typography></StyledTableCell>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{stokbarangs.stokk}</Typography></StyledTableCell>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(stokbarangs.hargak)}</Typography></StyledTableCell>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(stokbarangs.totalk)}</Typography></StyledTableCell>
                <StyledTableCell width={200} align="center"><Typography sx={{fontSize:12}}>{stokbarangs.stokbarang}</Typography></StyledTableCell>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(stokbarangs.modalbarang)}</Typography></StyledTableCell>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(stokbarangs.saldo)}</Typography></StyledTableCell>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{stokbarangs.keterangan}</Typography></StyledTableCell>
                </StyledTableRow>
            ))}
        </TableBody>
    )
    const validationSchema = yup.object().shape({
        tglawal:yup.date().max(yup.ref('tglakhir'),'Tanggal Awal tidak boleh melebihi tanggal akhir').max(new Date(),'Tanggal tidak boleh melebihi hari ini'),
        tglakhir:yup.date().min(yup.ref('tglawal'),'Tanggal Akhir tidak boleh kurang dari tanggal awal').max(new Date(),'Tanggal tidak boleh melebihi hari ini'),
        bulan:yup.string(),
        tahun:yup.string()
    })
    const formik=useFormik({
        initialValues:{
            tglawal:new Date(),
            tglakhir:new Date(),
            bulan:'',
            tahun:''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            if(disableTanggal===true && disablePeriode===true){
                const data={
                    'idsatuanbarang':idsatuanbarang
                }
                dispatch(getRincianStokBarangByAll(data,counter,token,setOpensnackbar));
            }
            else if(disableTanggal === false && disablePeriode === true){
                const data={
                    'idsatuanbarang':idsatuanbarang,
                    'tglawal':format(values.tglawal,"yyyy-MM-dd"),
                    'tglakhir':format(values.tglakhir,"yyyy-MM-dd"),
                }
                dispatch(getRincianStokBarangByTgl(data,counter,token,setOpensnackbar));
            }else if(disableTanggal === true && disablePeriode === false){
                const data={
                    'idsatuanbarang':idsatuanbarang,
                    'bulan':values.bulan,
                    'tahun':values.tahun,
                }
                dispatch(getRincianStokBarang(data,counter,token,setOpensnackbar));
            }
        }
    })
    const handlePrev = () =>{
        setCounter(counter <= 1? 1 : counter - 1);
    }
    const handleNext = () =>{
        setCounter(counter === pageRincianStokBarang.totalPageRincian ? pageRincianStokBarang.totalPageRincian : counter + 1);
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(setStokBarangMessage(''));
        }, 100);
    }
    const handleOpenEditStok = () =>{
        setOpenEditStokDialog(true);
    }
    const handleOpenKoreksiStok = () =>{
        setOpenKoreksiStok(true);
    }
    const handleCloseKoreksiStok = () =>{
        setOpenKoreksiStok(false);
        formik.handleSubmit();
    }
    const handleCloseEditStok=()=>{
        setOpenEditStokDialog(false);
        if(disableTanggal === false){
            const data={
                'idsatuanbarang':idsatuanbarang,
                'tglawal':format(formik.values.tglawal,"yyyy-MM-dd"),
                'tglakhir':format(formik.values.tglakhir,"yyyy-MM-dd"),
            }
            dispatch(getRincianStokBarangByTgl(data,counter,token,setOpensnackbar));
        }else if(disableTanggal === true){
            const data={
                'idsatuanbarang':idsatuanbarang,
                'bulan':formik.values.bulan,
                'tahun':formik.values.tahun,
            }
            dispatch(getRincianStokBarang(data,counter,token,setOpensnackbar));
        }
    }
    return (
    <Container maxWidth='xl' sx={{marginTop:'100px'}}>
    <Typography variant='h6' pl={1} mt={1}>Rincian stok barang {namabarang}</Typography>
    <Divider/>
    <Box sx={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
        <Box sx={{width:'100%',display:'flex',justifyContent:'flex-start'}}>
                <FormControl sx={{width:'100%',marginTop:'10px',display:'flex',alignContent:'flex-end'}}>
                <RadioGroup 
                    row 
                    name="ppn"
                    value={valueRadio}
                    onChange={handleChange}>
                        <Box sx={{display:'flex',alignItems:'center'}}>
                        <FormControlLabel  value="All" control={<Radio />} label="Semua" />
                        </Box>
                            <Box sx={{display:'flex',alignItems:'center'}}>
                                <FormControlLabel value="Tanggal" control={<Radio />} label="" />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                disabled={disableTanggal}
                                label="Tanggal Awal"
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) => <TextField {...params} sx={{width:'120px',marginRight:'10px'}} variant="standard"/>}
                                value={formik.values.tglawal}
                                error={formik.touched.tglawal && Boolean(formik.errors.tglawal)}
                                helperText={formik.touched.tglawal && formik.errors.tglawal}
                                onChange={(e)=>handleChangeTanggalAwal(e)}
                                />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                disabled={disableTanggal}
                                label="Tanggal Akhir"
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) => <TextField {...params} sx={{width:'120px',marginRight:'20px'}} variant="standard"/>}
                                value={formik.values.tglakhir}
                                error={formik.touched.tglakhir && Boolean(formik.errors.tglakhir)}
                                helperText={formik.touched.tglakhir && formik.errors.tglakhir}
                                onChange={(e)=>handleChangeTanggalAkhir(e)}
                                />
                                </LocalizationProvider>
                            </Box>
                            <Box sx={{display:'flex',alignItems:'center'}}>
                                    <FormControlLabel  value="Periode" control={<Radio />} label="Periode" />
                                    <TextField
                                    id='bulan'
                                    select
                                    name='bulan'
                                    disabled={disablePeriode}
                                    variant="standard"
                                    value={formik.values.bulan}
                                    error={formik.touched.bulan && Boolean(formik.errors.bulan)}
                                    helperText={formik.touched.bulan && formik.errors.bulan}
                                    sx={{marginRight:'5px'}}
                                    SelectProps={{
                                        native: true,
                                        }}
                                    onChange={formik.handleChange}
                                    >   
                                        <option aria-label="None" value="" />
                                        {bulanCombo.map((option)=>(
                                        <option key ={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                    </TextField>
                                    <TextField
                                    id='tahun'
                                    select
                                    disabled={disablePeriode}
                                    name='tahun'
                                    variant="standard"
                                    value={formik.values.tahun}
                                    error={formik.touched.tahun && Boolean(formik.errors.tahun)}
                                    helperText={formik.touched.tahun && formik.errors.tahun}
                                    sx={{width:'100px',marginRight:'5px'}}
                                    SelectProps={{
                                        native: true,
                                        }}
                                    onChange={formik.handleChange}
                                    >   
                                        <option aria-label="None" value="" />
                                        {listTahun.map((option)=>(
                                        <option key ={option.tahun} value={option.tahun}>
                                            {option.tahun}
                                        </option>
                                    ))}
                                    </TextField>
                                    <Tooltip title="Tampilkan Data">
                                        <IconButton onClick={formik.handleSubmit}>
                                            <ContentPasteSearchIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Ubah Stok Akhir">
                                        <IconButton onClick={handleOpenEditStok}>
                                            <img src={EditStokAkhir} alt="editStokAkhir"/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Koreksi Stok">
                                        <IconButton onClick={handleOpenKoreksiStok}>
                                            <img src={KoreksiStok} alt="koreksiStok"/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                    </RadioGroup>
                </FormControl>
        </Box>
        <TableContainer component={Paper} sx={paperTable}>
            <Table stickyHeader aria-label="customized table" size='small'>
                <TableHead>
                <TableRow>
                    <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Tanggal</Typography></StyledTableCell>
                    <StyledTableCell width={200} align="center"><Typography sx={{fontSize:12}}>Nama Barang</Typography></StyledTableCell>
                    <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Kelompok</Typography></StyledTableCell>
                    <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Satuan</Typography></StyledTableCell>
                    <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Stok M</Typography></StyledTableCell>
                    <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Modal M</Typography></StyledTableCell>
                    <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Total M</Typography></StyledTableCell>
                    <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Stok K</Typography></StyledTableCell>
                    <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Modal K</Typography></StyledTableCell>
                    <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Total K</Typography></StyledTableCell>
                    <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Stok</Typography></StyledTableCell>
                    <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Modal</Typography></StyledTableCell>
                    <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Saldo</Typography></StyledTableCell>
                    <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Keterangan</Typography></StyledTableCell>
                </TableRow>
                </TableHead>
                    {tableStokBarang} 
            </Table>
        </TableContainer>
            <Box sx={boxSearch}>
                <TablePagination
                    page={counter}
                    totalPage={pageRincianStokBarang.totalPageRincian >= 1 ? pageRincianStokBarang.totalPageRincian : pageRincianStokBarang.currentPageRincian}
                    handlePrev={handlePrev}
                    handleNext={handleNext}
                    hiddenButton={true}
                />
            </Box>
    </Box>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        >
        <CircularProgress color="inherit" />
    </Backdrop>
    <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={stokbarangMessage === "Berhasil menampilkan data stok barang" ? "success" : "error"}
                >
                    {stokbarangMessage}
                    </Alert>
            </Snackbar>
            <Fab sx={{position: 'fixed',bottom: theme.spacing(5),left: theme.spacing(3)}} size="small" color="primary" aria-label="add" onClick={()=>history.push('/stokbarang')}>
            <ArrowBackIcon/>
        </Fab>
        <EditStokAkhirDialog openDialog={openEditStokDialog} idsatuan={idsatuanbarang}  handleCloseDialog={handleCloseEditStok}/>
        <KoreksiStokDialog openKoreksi={openKoreksiStok} closeKoreksi={handleCloseKoreksiStok}/>
    </Container>
  )
}

export default RincianStokBarang