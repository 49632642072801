import { Box,  Container, Divider, IconButton, Paper, Snackbar, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { ExportToXLSX, TablePagination } from '../../../components/molecules';
import { getDataPPN, setEmptyPPNMessage } from '../../../config/redux/actions';
import { format } from 'date-fns';
import { EditData } from '../../../components/assets';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import * as yup from 'yup';
import { useFormik } from 'formik';
import EditPPNMasukan from '../EditPPNMasukan';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'grey',
      color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const PPNPage = () => {
  const theme = useTheme();
    const paperTable = {
        marginTop:'10px',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            height:'400px',
        }, 
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'360px'
        },
        scrollbarWidth:'thin'
    }
    const boxSearch = {
        [theme.breakpoints.up('sm')]:{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'flex-end',
            width:'100%',
        }, 
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'flex-end',
            width:'100%',
        }
    }
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const {ppnMessage,pagePPN,dataPPN} = useSelector(state=>state.ppnReducers);
    const [token,setToken] = useState(dataLogin.token);
    const [counter,setCounter] = useState(1);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [openPPNMasukan,setOpenPPNMasukan]=useState(false);
    const [idPPN,setIdPPN]=useState('');
    const [fakturPPN,setFakturPPN]=useState('');
    const [statusPPN,setStatusPPN]=useState('');
    const [debet,setDebet]=useState(0);
    const [kredit,setKredit]=useState(0);
    const [saldo,setSaldo]=useState(0);
    const [disabledButton,setDisabledButton]=useState(true);
    const dispatch = useDispatch();
    if(token ===''){
      setToken(Cookies.get('u_tkn'));
    }
    const handleCloseSnackbar = (event,reason) =>{
      if(reason === 'clickaway'){
          return;
      }
      setOpensnackbar(false);
      setTimeout(() => {
          dispatch(setEmptyPPNMessage(''));
      }, 100);
  }
  useEffect(()=>{
    //  if(dataPPN.length<1){
    //   const data = {
    //     'tglawal':format(new Date(),"yyyy-MM-dd"),
    //     'tglakhir':format(new Date(),"yyyy-MM-dd")
    //   }
    //   dispatch(getDataPPN(data,counter,token,setOpensnackbar));

    //  }
      if(dataPPN.length>0){
        let totalDebet=dataPPN.reduce((Debet,TotalD)=>{
            return Debet + TotalD.debet
        },0);
        let totalKredit = dataPPN.reduce((Kredit,TotalK)=>{
            return Kredit + TotalK.kredit
        },0)
        let totalSaldo = totalDebet-totalKredit;
        setDebet(Math.ceil(totalDebet));
        setKredit(Math.ceil(totalKredit));
        setSaldo(Math.ceil(totalSaldo));
        setDisabledButton(false);
      }else{
        setDebet(0);
        setKredit(0);
        setSaldo(0);
        setDisabledButton(true);
      }
  },[counter,token,dispatch,dataPPN])  
    const handlePrev = () =>{
      setCounter(counter <= 1? 1 : counter - 1);
  }
  const handleNext = () =>{
      setCounter(counter === pagePPN.totalPage ? pagePPN.totalPage : counter + 1);
  }
  const handleOpenPPNMasukan = (id,fakturpajak,status)=>{
    setIdPPN(id);
    setFakturPPN(fakturpajak);
    setStatusPPN(status);
    setOpenPPNMasukan(true);
  }
  
    const tableDataPPN = (
      <TableBody>
          {dataPPN.map((ppn) => (
              <StyledTableRow key={ppn.idppn}>
              <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{format(new Date(ppn.tanggal),'dd/MM/yyyy')}</Typography></StyledTableCell>
              <StyledTableCell width={200} align="center"><Typography sx={{fontSize:12}}>{ppn.faktur}</Typography></StyledTableCell>
              <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{ppn.fakturpajak}</Typography></StyledTableCell>
              <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{ppn.tahun}</Typography></StyledTableCell>
              <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(ppn.dpp)}</Typography></StyledTableCell>
              <StyledTableCell width={200} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(ppn.ppn)}</Typography></StyledTableCell>
              <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(ppn.debet)}</Typography></StyledTableCell>
              <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(ppn.kredit)}</Typography></StyledTableCell>
              {/* <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(ppn.saldo)}</Typography></StyledTableCell> */}
              <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{ppn.status}</Typography></StyledTableCell>
              <StyledTableCell width={250} align="center"><Typography sx={{fontSize:12}}>{ppn.keterangan}</Typography></StyledTableCell>
              <StyledTableCell width={20} align="center">
                <Tooltip title="Tambahkan Faktur Pajak">
                  <IconButton onClick={()=>handleOpenPPNMasukan(ppn.idppn,ppn.fakturpajak,ppn.status)}>
                    <img src={EditData} alt="editPPN"/>
                  </IconButton>
                </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
          ))}
      </TableBody>
  )
  const handleChangeTanggalAwal = (e)=>{
    formik.setFieldValue('tglawal',e);
}
const handleChangeTanggalAkhir = (e)=>{
    formik.setFieldValue('tglakhir',e);
}
  const validationSchema = yup.object().shape({
    tglawal:yup.date().max(yup.ref('tglakhir'),'Tanggal Awal tidak boleh melebihi tanggal akhir').max(new Date(),'Tanggal tidak boleh melebihi hari ini'),
    tglakhir:yup.date().min(yup.ref('tglawal'),'Tanggal Akhir tidak boleh kurang dari tanggal awal').max(new Date(),'Tanggal tidak boleh melebihi hari ini'),
 })
const formik=useFormik({
    initialValues:{
        tglawal:new Date(),
        tglakhir:new Date()
    },
    validationSchema:validationSchema,
    onSubmit:(values)=>{
        const data={
            'tglawal':format(values.tglawal,"yyyy-MM-dd"),
            'tglakhir':format(values.tglakhir,"yyyy-MM-dd"),
        }
        dispatch(getDataPPN(data,counter,token,setOpensnackbar));
    }
})
const handleClosePPNMasukan = () =>{
  const data={
    'tglawal':format(formik.values.tglawal,"yyyy-MM-dd"),
    'tglakhir':format(formik.values.tglakhir,"yyyy-MM-dd"),
  }
  dispatch(getDataPPN(data,counter,token,setOpensnackbar));
  setOpenPPNMasukan(false);
}
let fileName = "PPN periode "+format(formik.values.tglawal,"ddMMyyyy")+"-"+format(formik.values.tglakhir,"ddMMyyyy");
  
  return (
    <Container maxWidth='xl' sx={{marginTop:'100px'}}>
        <Typography variant='h6' pl={1} mt={1}>Data PPN</Typography>
        <Divider/>
        <Box sx={{marginTop:'5px',width:'100%',display:'flex',flexDirection:'column'}}>
          <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                label="Tanggal Awal"
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} sx={{width:'120px',marginRight:'40px'}} variant="standard"/>}
                value={formik.values.tglawal}
                error={formik.touched.tglawal && Boolean(formik.errors.tglawal)}
                helperText={formik.touched.tglawal && formik.errors.tglawal}
                onChange={(e)=>handleChangeTanggalAwal(e)}
                />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                label="Tanggal Akhir"
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} sx={{width:'120px',marginRight:'20px'}} variant="standard"/>}
                value={formik.values.tglakhir}
                error={formik.touched.tglakhir && Boolean(formik.errors.tglakhir)}
                helperText={formik.touched.tglakhir && formik.errors.tglakhir}
                onChange={(e)=>handleChangeTanggalAkhir(e)}
                />
                </LocalizationProvider>
                <Tooltip title="Tampilkan data">
                  <IconButton onClick={formik.handleSubmit}>
                    <ContentPasteSearchIcon/>
                  </IconButton>
                </Tooltip>
                <ExportToXLSX csvData={dataPPN} fileName={fileName} disabled={disabledButton}/>
            </Box>
            <TableContainer component={Paper} sx={paperTable}>
                  <Table stickyHeader aria-label="customized table" size='small'>
                      <TableHead>
                      <TableRow>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Tanggal</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Faktur</Typography></StyledTableCell>
                          <StyledTableCell width={200} align="center"><Typography sx={{fontSize:12}}>Faktur Pajak</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Tahun</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>DPP</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>PPN</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Debet</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Kredit</Typography></StyledTableCell>
                          {/* <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Saldo</Typography></StyledTableCell> */}
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Status</Typography></StyledTableCell>
                          <StyledTableCell width={250} align="center"><Typography sx={{fontSize:12}}>Keterangan</Typography></StyledTableCell>
                          <StyledTableCell width={20} align="center"></StyledTableCell>
                      </TableRow>
                      </TableHead>
                          {tableDataPPN} 
                  </Table>
               </TableContainer>
                <Box sx={boxSearch}>
                    <TablePagination
                        page={counter}
                        totalPage={pagePPN.totalPage >= 1 ? pagePPN.totalPage : pagePPN.currentPage}
                        handlePrev={handlePrev}
                        handleNext={handleNext}
                        hiddenButton={true}
                    />
                </Box>
                <Box sx={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                  <Box sx={{width:'40%',display:'flex',justifyContent:'space-between'}}>
                    <Typography>Debet : {new Intl.NumberFormat().format(debet)}</Typography>
                    <Typography>Kredit : {new Intl.NumberFormat().format(kredit)}</Typography>
                    <Typography>Saldo : {new Intl.NumberFormat().format(saldo)}</Typography>
                  </Box>
                </Box>
        </Box>
        <EditPPNMasukan openPPNMasukan={openPPNMasukan} status={statusPPN} handleClosePPNMasukan={handleClosePPNMasukan} fakturpajak={fakturPPN} idppn={idPPN}/>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={ppnMessage === 'Penghapusan data barang berhasil'? 'success':'error'}
                >
                    {ppnMessage}
                    </Alert>
            </Snackbar>
    </Container>
  )
}

export default PPNPage