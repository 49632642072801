import { Button, Divider, Paper, Snackbar, TextField,IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SignupLogo } from '../../assets'
import MuiAlert from '@mui/material/Alert';
import * as yup from 'yup';
import { useFormik} from 'formik'
import { createNewUser, emptyUserMessage } from '../../../config/redux/actions/penggunaActions'
import { useHistory } from 'react-router-dom'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const SignUpCard = () => {
    const sytlePaper={
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        width:'320px',
        height:'460px',
        borderRadius:'15px',
        backgroundColor:'#edeefa',
        opacity:0.95,
        border:'0.5px solid grey'
    }
    const dispatch = useDispatch();
    const {penggunaMessage}=useSelector(state=>state.penggunaReducers);
    const history = useHistory();
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [showPassword,setShowPassword]=useState(false);
    const [showUlangPassword,setShowUlangPassword] = useState(false);
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptyUserMessage(''));
        }, 100);
    }
    const schemaValidation = yup.object().shape({
        idpengguna:yup.string().required("ID Pengguna tidak boleh kosong").matches(/^(?=.{6,10}$)(?![_.])[a-zA-Z0-9._]/,"Id pengguna harus terdiri dari 6 sampai 10 karakter dan tidak boleh mengandung karakter _ atau ."),
        nama:yup.string().required("Nama tidak boleh kosong"),
        password:yup.string().required("Password tidak boleh kosong").matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
            "Kata sandi harus terdiri dari 8 karakter, 1 huruf besar, 1 huruf kecil, 1 nomor dan 1 karakter spesial"
          ),
        ulangpassword:yup.string()
        .oneOf([yup.ref('password'), null], 'Ulang kata sandi tidak sesuai')
    })
    const formik = useFormik({
        initialValues:{
            idpengguna:'',
            nama:'',
            password:'',
            ulangpassword:''
        },
        validationSchema:schemaValidation,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('idpengguna',values.idpengguna);
            data.append('nama',values.nama);
            data.append('password',values.password);
            data.append('hakakses','Pemilik');
            data.append('modul_pengguna','Ya');
            data.append('modul_pelanggan','Ya');
            data.append('modul_distributor','Ya');
            data.append('modul_stokbarang','Ya');
            data.append( 'modul_barang','Ya');
            data.append('modul_harga','Ya');
            data.append('modul_pembelian','Ya');
            data.append('modul_penjualan','Ya');
            data.append('modul_retur','Ya');
            data.append('modul_laporan','Ya');
            dispatch(createNewUser(data,setOpensnackbar,history));
        }
    })
    const handleClickShowPassword = () =>{
        setShowPassword(!showPassword);
    }
    
    const handleClickShowUlangPassword = () =>{
        setShowUlangPassword(!showUlangPassword);
    }
    return (
        <div>
            <Paper sx={sytlePaper} elevation={3}>
            <img src={SignupLogo} width='100px' height="100px" alt='loginIcon' style={{paddingTop:'5px'}}/>
                <Box width='100%' mt={3} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                <Divider style={{width:'100%'}}/>
                <TextField
                    id="idpengguna"
                    name="idpengguna"
                    label="ID Pengguna"
                    variant="standard"
                    style={{width:'80%',marginTop:'2px'}}
                    value={formik.values.idpengguna}
                    error={formik.touched.idpengguna && Boolean(formik.errors.idpengguna)}
                    helperText={formik.touched.idpengguna && formik.errors.idpengguna}
                    onChange={formik.handleChange}
                    />
                    <TextField
                    id="nama"
                    name="nama"
                    label="nama"
                    variant="standard"
                    style={{width:'80%',marginTop:'2px'}}
                    value={formik.values.nama}
                    error={formik.touched.nama && Boolean(formik.errors.nama)}
                    helperText={formik.touched.nama && formik.errors.nama}
                    onChange={formik.handleChange}
                    />
                    <Box sx={{width:'80%',display:'flex',alignItems:'flex-end',marginTop:'2px'}}>
                    <TextField
                    id="password"
                    label="Kata Sandi"
                    type={showPassword ? 'text':'password'}
                    variant="standard"
                    style={{width:'85%'}}
                    value={formik.values.password}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    onChange={formik.handleChange}
                    />
                    <IconButton 
                    arial-label='toggle-password'
                    onClick={handleClickShowPassword}
                    >
                    {showPassword?<Visibility/>:<VisibilityOff/>}
                    </IconButton>
                    </Box>
                    <Box sx={{width:'80%',display:'flex',alignItems:'flex-end',marginTop:'2px'}}>
                    <TextField
                    id="ulangpassword"
                    label="Ulang Kata Sandi"
                    type={showUlangPassword ? 'text':'password'}
                    variant="standard"
                    style={{width:'85%'}}
                    value={formik.values.ulangpassword}
                    error={formik.touched.ulangpassword && Boolean(formik.errors.ulangpassword)}
                    helperText={formik.touched.ulangpassword && formik.errors.ulangpassword}
                    onChange={formik.handleChange}
                    />
                    <IconButton 
                    arial-label='toggle-password'
                    onClick={handleClickShowUlangPassword}
                    >
                    {showUlangPassword?<Visibility/>:<VisibilityOff/>}
                    </IconButton>
                    </Box>
                    <Button style={{marginTop:'20px',width:'60%'}} variant='contained' color='primary' onClick={formik.handleSubmit}>SIMPAN</Button>
                    <Box display='flex' width='100%' height='70px' pr='20px' justifyContent='flex-end' alignItems='flex-end'>
                        <Typography variant='caption' style={{fontFamily:'cursive'}}>Toko Jaya Makmur</Typography>
                    </Box>
                </Box>
            </Paper>
            <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={penggunaMessage==='Berhasil mendaftarkan pengguna baru' ?"success" : "error"}
                >
                    {penggunaMessage}
                    </Alert>
            </Snackbar>
        </div>
    )
}

export default SignUpCard
