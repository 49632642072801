import { Box, Drawer, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {useSelector,useDispatch} from 'react-redux';
import { OpenLeftDrawer } from '../../../config/redux/actions/homeActions';
import { CustomerIcon, DaftarHarga, HomeIcon, PembelianIcon, PenjualanIcon,  PPNIcon, ProductIcon, ReturIcon, StokIcon, SupplierIcon, UnitIcon, UserIcon } from '../../assets';
import { MenuList } from '../../molecules';
import {Link, useHistory} from 'react-router-dom';
import Cookies from 'js-cookie';
import { emptyPembelianForm, emptyUserDetails, getDetailsPengguna } from '../../../config/redux/actions';

const LeftMenu = () => {
    const {openDrawer} = useSelector(state=>state.homeReducers);
    const dispatch = useDispatch();
    const handleClick = ()=>{
        dispatch(OpenLeftDrawer(!openDrawer));
    }
    const history = useHistory();
    const {dataLogin,detailsPengguna} =useSelector(state=>state.penggunaReducers);
    const [uid,setUid]  = useState(dataLogin.u_name);
    const [token,setToken] = useState(dataLogin.token);
    const [Pengguna,setPengguna] = useState(false);
    const [Pelanggan,setPelanggan] = useState(false);
    const [Distributor,setDistributor] = useState(false);
    const [StokBarang,setStokBarang] = useState(false);
    const [Barang,setBarang] = useState(false);
    const [Harga,setHarga] = useState(false);
    const [Pembelian,setPembelian] = useState(false);
    const [Penjualan,setPenjualan] = useState(false);
    const [Retur,setRetur] = useState(false);
    const [Laporan,setLaporan] = useState(false);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
        setUid(Cookies.get('u_name'));
    }
    useEffect(()=>{
        if(detailsPengguna===''){
            dispatch(getDetailsPengguna(uid,token,"",history));
        }
        detailsPengguna.m_pengguna === 'true' ? setPengguna(false) : setPengguna(true);
        detailsPengguna.m_pelanggan === 'true' ? setPelanggan(false) : setPelanggan(true);
        detailsPengguna.m_distributor === 'true' ? setDistributor(false) : setDistributor(true);
        detailsPengguna.m_stokbarang === 'true' ? setStokBarang(false) : setStokBarang(true);
        detailsPengguna.m_barang === 'true' ? setBarang(false) : setBarang(true);
        detailsPengguna.m_harga === 'true' ? setHarga(false) : setHarga(true);
        detailsPengguna.m_pembelian === 'true' ? setPembelian(false) : setPembelian(true);
        detailsPengguna.m_penjualan === 'true' ? setPenjualan(false) : setPenjualan(true);
        detailsPengguna.m_retur === 'true' ? setRetur(false) : setRetur(true);
        detailsPengguna.m_laporan === 'true' ? setLaporan(false) : setLaporan(true);
    },[dispatch,uid,token,detailsPengguna])

    const handleClickHalamanUtama = ()=>{
        dispatch(emptyUserDetails());
    }
    const handleClickPembelian = ()=>{
        dispatch(emptyPembelianForm());
        history.push('/pembelian')
    }
    return (
        <Drawer
        variant='temporary'
        open={openDrawer}
        onClick={handleClick}
        ModalProps={{keepMounted:true}}
         >
             <Box sx={{width:'250px',height:'100vh',backgroundColor:'#727070',paddingTop:'5px'}}>
                <Link to='/' style={{textDecoration:'none',color:'black'}}>
                    <MenuList label="Halaman Utama" icon={HomeIcon} alt="dashboard" handleClick={handleClickHalamanUtama}/>
                </Link>
                <Typography pt={1} pl={1} variant='h6' color="whitesmoke">Master Data</Typography>
                <Link style={{textDecoration:"none",color:'black'}} to={Pengguna === false ? "/pengguna" :"/"}>
                    <MenuList disabled={Pengguna} label="Pengguna" icon={UserIcon} alt="userMenu"/>
                </Link>
                <Link style={{textDecoration:"none",color:'black'}} to={Distributor === false ? "/distributor" :"/"}>
                    <MenuList disabled={Distributor} label="Distributor" icon={SupplierIcon} alt="supplierMenu"/>
                </Link>
                <Link style={{textDecoration:"none",color:'black'}} to={Pelanggan === false ? "/pelanggan" :"/"}>
                    <MenuList disabled={Pelanggan} label="Pelanggan" icon={CustomerIcon} alt="customerMeru"/>
                </Link>
                <Link style={{textDecoration:"none",color:'black'}} to={Barang === false ? "/barang" :"/"}>
                    <MenuList disabled={Barang} label="Barang" icon={ProductIcon} alt="productMenu"/>
                </Link>
                <Link style={{textDecoration:"none",color:'black'}} to={Harga === false ? "/hargabarang" :"/"}>
                    <MenuList disabled={Harga} label="Daftar Harga" icon={DaftarHarga} alt="daftarHargaMenu"/>
                </Link>
                <Link style={{textDecoration:"none",color:'black'}} to={Barang === false ? "/satuan" :"/"}>
                    <MenuList disabled={Barang} label="Satuan" icon={UnitIcon} alt="unitMenu"/>
                </Link>
                <Link style={{textDecoration:"none",color:'black'}} to={Pembelian === false ? "/pembelian" :"/"}>
                    <MenuList disabled={Pembelian} label="Pembelian" icon={PembelianIcon} alt="pembelianMenu" handleClick={()=>handleClickPembelian()}/>
                </Link>
                <Link style={{textDecoration:"none",color:'black'}} to={StokBarang === false ? "/stokbarang" :"/"}>
                    <MenuList disabled={StokBarang} label="Stok Barang" icon={StokIcon} alt="stokMenu" handleClick={()=>history.push('/stokbarang')}/>
                </Link>
                <Link style={{textDecoration:"none",color:'black'}} to={Penjualan === false ? "/penjualan" : "/"}>
                    <MenuList disabled={Penjualan} label="Penjualan" icon={PenjualanIcon} alt="penjualanMenu" handleClick={()=>history.push('/penjualan')}/>
                </Link>
                <Link style={{textDecoration:"none",color:'black'}} to={Retur === false ? "/stokretur" : "/"}>
                    <MenuList disabled={Retur} label="Stok Retur" icon={ReturIcon} alt="returMenu" handleClick={()=>history.push('/stokretur')}/>
                </Link>
                {detailsPengguna.hakakses === 'Pemilik' ? 
                <Link style={{textDecoration:"none",color:'black'}} to='/ppn'>
                    <MenuList disabled={StokBarang} label="PPN" icon={PPNIcon} alt="ppnMenu" handleClick={()=>history.push('/ppn')}/>
                </Link> : null}
                {detailsPengguna.hakakses === 'Pemilik' ? 
                <Link style={{textDecoration:"none",color:'black'}} to='/laporan'>
                    <MenuList disabled={Laporan} label="Laporan" icon={DaftarHarga} alt="laporanMenu" handleClick={()=>history.push('/laporan')}/>
                </Link> : null}
            </Box>
        </Drawer>
    )
}

export default LeftMenu
