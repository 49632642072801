import { Box, Container } from '@mui/material'
import React from 'react'
import {useTheme} from '@mui/material/styles';
import { SignUpCard } from '../../../components/organism';
import { loginBGDesktop, loginBGMobile } from '../../../components/assets';

const Signup = () => {
    const theme = useTheme();
    const container = {
        [theme.breakpoints.up('sm')]:{
            backgroundImage:`url(${loginBGDesktop})`,
            backgroundRepeat:'no-repeat',
            backgroundPosition:'center',
            width:'100%',
            height:'100vh'
        },
        [theme.breakpoints.down('sm')]:{
            backgroundImage:`url(${loginBGMobile})`,
            backgroundRepeat:'no-repeat',
            backgroundPosition:'center',
            width:'100%',
            height:'100vh'
        },
    };
    return (
        <div>
            <Container component='div' maxWidth='xl' sx={container}>
                <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
                    <SignUpCard/>
                </Box>
            </Container>
        </div>
    )
}

export default Signup